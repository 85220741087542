import moment from "moment";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { BsYoutube } from "react-icons/bs";
import { HiArrowLongLeft } from "react-icons/hi2";
import { IoMdTrash } from "react-icons/io";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import GlobalLoader from "../../../../components/GlobalLoader";
import useTitle from "../../../../hooks/useTitle";
import { useDeleteClaimRequestMutation, useGetAllClaimRequestsQuery } from "../../../../redux/features/ClaimRequestApi";

export default function ClaimRequests() {
          useTitle("Manage All Claim Requests");

          const { data, isLoading, refetch } = useGetAllClaimRequestsQuery({});

          const claimRequests = data?.data;

          const [deleteRequest, { isSuccess, error }] = useDeleteClaimRequestMutation();

          const deleteClaimRequest = (id: string) => {
                    Swal.fire({
                              title: 'Are you sure?',
                              text: "You want to delete this claim request?",
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, delete it!'
                    }).then(async (result) => {
                              if (result.isConfirmed) {
                                        await deleteRequest({ id });
                              }
                    })
          }

          useEffect(() => {
                    if (isSuccess) {
                              toast.success("Claim request deleted successfully");
                              refetch();
                    }
                    if (error) {
                              toast.error((error as any)?.data?.message);
                    }
          }, [data, isSuccess, error, refetch]);

          if (isLoading) {
                    return <GlobalLoader />
          }

          return (
                    <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
                              <div className="title my-2 mb-5">
                                        <h3 className="text-2xl font-bold flex items-center gap-2"> Manage All Claim Requests</h3>
                                        <span>
                                                  You can manage all claim requests here
                                        </span>
                              </div>
                              {
                                        claimRequests?.length > 0 ? (
                                                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                                                            {
                                                                      claimRequests?.map((claim: any, index: number) => (
                                                                                <div key={index} className="border card rounded-xl shadow-md">
                                                                                          <div className="card-body relative">
                                                                                                    <div className="absolute top-1 right-1">
                                                                                                              <button className="btn btn-xs bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white"
                                                                                                                        onClick={() => deleteClaimRequest(claim?._id)}
                                                                                                              >
                                                                                                                        <IoMdTrash />
                                                                                                              </button>
                                                                                                    </div>
                                                                                                    <p className="text-gray-500 flex items-center gap-2">UPC Code: <span className="badge bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white p-3 cursor-pointer"
                                                                                                              onClick={() => {
                                                                                                                        navigator.clipboard.writeText(claim?.upcCode)
                                                                                                                        toast.success("UPC Code copied to clipboard")
                                                                                                              }}>
                                                                                                              {claim?.upcCode}
                                                                                                    </span> <HiArrowLongLeft /> <small className="text-xs">Click to copy</small>
                                                                                                    </p>
                                                                                                    <p className="text-gray-500 flex items-center gap-2">Youtube URL:
                                                                                                              <span className="badge bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white p-3 cursor-pointer"
                                                                                                                        onClick={() => {
                                                                                                                                  navigator.clipboard.writeText(claim?.ytUrl)
                                                                                                                                  toast.success("Youtube URL copied to clipboard")
                                                                                                                        }}>
                                                                                                                        <BsYoutube />
                                                                                                              </span> <HiArrowLongLeft /> <small className="text-xs">Click to copy</small>
                                                                                                    </p>
                                                                                                    <small className="text-gray-500">Requested at: {moment(claim?.createdAt).format("Do MMM YYYY, hh:mm:ss A")}</small>
                                                                                                    <p className="text-gray-500 mt-2 text-lg font-bold">User Details</p>
                                                                                                    <small className="text-gray-500">Name: <span className="">{claim?.user?.firstName} {claim?.user?.lastName}</span></small>
                                                                                                    <small className="text-gray-500">Email: <span className="">{claim?.user?.email}</span></small>
                                                                                                    <div>
                                                                                                              <Link to={`/all-artists/${claim?.user?._id}`} className="btn btn-xs bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white mt-2">View User</Link>
                                                                                                    </div>
                                                                                          </div>
                                                                                </div>
                                                                      ))
                                                            }
                                                  </div>
                                        ) : (
                                                  <div className="card flex flex-col justify-center items-center mx-auto w-full md:w-[300px] mt-24">
                                                            <div className="card-body">
                                                                      <h1 className='text-center text-sm text-red-600'>
                                                                                Oops! No claim requests found.
                                                                      </h1>
                                                            </div>
                                                  </div>
                                        )
                              }
                    </div>
          )
}