import { useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineUser } from 'react-icons/ai';
import { BiShieldAlt2 } from 'react-icons/bi';
import { BsFillPlusCircleFill, BsGear, BsGrid, BsMusicNote, BsYoutube } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { GiMusicalNotes } from 'react-icons/gi';
import { IoMdLogOut } from 'react-icons/io';
import { IoWalletOutline } from 'react-icons/io5';
import { MdSpaceDashboard, MdVerified } from 'react-icons/md';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import LOGO from '../../assets/logo.png';
import HomeLoader from '../../components/HomeLoader';
import useAuth from '../../hooks/useAuth';
import useTitle from '../../hooks/useTitle';
import { authUserInterface } from '../../interfaces/UserInterface';
import { useLogOutAuthMutation } from '../../redux/features/auth/AuthApi';
import { logout } from '../../redux/features/auth/AuthSlice';
import { useAppDispatch } from '../../redux/store';

export default function Dashboard() {
          useTitle('Dashboard');
          const [logOut] = useLogOutAuthMutation();
          const dispatch = useAppDispatch();
          const navigate = useNavigate();

          const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

          const {
                    user,
                    setUser,
                    updatedUser: data,
                    isLoading,
          } = useAuth<authUserInterface | any>({});
          const role = data?.role;

          const handleLogOut = async (id: string) => {
                    try {
                              await logOut({ id });
                              dispatch(logout());
                              setUser(null);
                              toast.success('Logout Successfully');
                              navigate('/login');
                    } catch (error) {
                              toast.error('Something went wrong');
                    }
          }

          let menuArray = [
                    {
                              id: 1,
                              title: 'Dashboard',
                              icon: <BsGrid />,
                              link: '/',
                    },
                    {
                              id: 2,
                              title: 'All Releases',
                              icon: <GiMusicalNotes />,
                              link: '/releases',
                    },
                    {
                              id: 3,
                              title: 'New Release',
                              icon: <BsMusicNote />,
                              link: '/new-release',
                    },
                    {
                              id: 4,
                              title: 'Wallet',
                              icon: <IoWalletOutline />,
                              link: '/wallet',
                    },
                    {
                              id: 5,
                              title: 'Claim Release Request',
                              icon: <BsYoutube />,
                              link: '/claim-release',
                    },
                    {
                              id: 6,
                              title: 'Setting',
                              icon: <BsGear />,
                              link: '/setting',
                    },
          ];

          if (role === 'admin') {
                    menuArray = [
                              {
                                        id: 1,
                                        title: 'Dashboard',
                                        icon: <BsGrid />,
                                        link: '/',
                              },
                              {
                                        id: 2,
                                        title: 'Manage Applications',
                                        icon: <FaUsers />,
                                        link: '/applications',
                              },
                              {
                                        id: 3,
                                        title: 'Manage Releases',
                                        icon: <GiMusicalNotes />,
                                        link: '/all-releases',
                              },
                              {
                                        id: 4,
                                        title: 'Manage Artists',
                                        icon: <FiUsers />,
                                        link: '/all-artists',
                              },
                              {
                                        id: 5,
                                        title: 'Manage Admins',
                                        icon: <BiShieldAlt2 />,
                                        link: '/all-admins',
                              },
                              {
                                        id: 6,
                                        title: 'Manage Verify Requests',
                                        icon: <MdVerified />,
                                        link: '/all-verify-requests',
                              },
                              {
                                        id: 7,
                                        title: 'Claim Release Requests',
                                        icon: <BsYoutube />,
                                        link: '/claim-requests',
                              },
                              {
                                        id: 8,
                                        title: 'Setting',
                                        icon: <BsGear />,
                                        link: '/setting',
                              },
                    ]
          }

          const closeDrawer = () => {
                    const checkbox = document.getElementById('dashboard-sidebar') as HTMLInputElement;
                    if (window.innerWidth < 1024) {
                              checkbox.checked = false;
                    }
          }

          if (isLoading) {
                    return <HomeLoader />
          }

          return (
                    <div className="drawer lg:drawer-open">
                              <input id="dashboard-sidebar" type="checkbox" className="drawer-toggle" />
                              <div className="drawer-content p-3">
                                        <div className={`header bg-globalColor z-50 sticky top-3 flex justify-between items-center shadow-lg p-4 rounded-xl`}>
                                                  <label
                                                            htmlFor="dashboard-sidebar"
                                                            className={`btn btn-outline duration-500 drawer-button group lg:hidden text-white border-white hover:bg-gray-600 hover:border-gray-600`}
                                                  >
                                                            <MdSpaceDashboard className={`text-2xl duration-500 text-white`} />
                                                  </label>
                                                  <div className={`flex items-center gap-1 text-white`}>
                                                            <h1 className="text-lg md:text-2xl font-semibold hidden md:flex">
                                                                      Welcome to
                                                            </h1>
                                                            <Link
                                                                      to="/"
                                                                      className={`text-lg md:text-2xl font-semibold`}
                                                            >
                                                                      Mirror-LTD Music
                                                            </Link>
                                                            {
                                                                      role === 'admin' ? (
                                                                                <h1 className="text-lg md:text-2xl font-semibold hidden md:flex capitalize">
                                                                                          ({role} Panel)
                                                                                </h1>
                                                                      ) : (
                                                                                <h1 className="text-lg md:text-2xl font-semibold hidden md:flex capitalize">
                                                                                          Dashboard
                                                                                </h1>
                                                                      )
                                                            }
                                                  </div>
                                                  <div className="dropdown dropdown-end">
                                                            <label
                                                                      tabIndex={0}
                                                                      className="btn btn-ghost btn-circle avatar online"
                                                                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                                            >
                                                                      <div
                                                                                style={{ display: "grid" }}
                                                                                className={`w-10 h-10 place-items-center rounded-full ring ring-gradientPrimary ring-offset-base-100 ring-offset-2`}
                                                                      >
                                                                                <img
                                                                                          src={data?.profileImage}
                                                                                          alt={data?.firstName}
                                                                                />
                                                                      </div>
                                                            </label>
                                                            {isDropdownOpen && (
                                                                      <ul
                                                                                tabIndex={0}
                                                                                className={`mt-6 -mr-4 p-2 shadow-xl menu menu-compact dropdown-content rounded-box w-72 relative bg-globalColor`}
                                                                      >
                                                                                <div className={`w-16 h-16 overflow-hidden rounded-full flex items-center justify-center mx-auto my-4 ring ring-gradientPrimary ring-offset-base-100 ring-offset-2`}>
                                                                                          <img
                                                                                                    src={data?.profileImage}
                                                                                                    alt={data?.firstName || ""}
                                                                                                    className="rounded-full "
                                                                                          />
                                                                                </div>
                                                                                <div className={`text-center mb-4 text-white`}>
                                                                                          <span className="font-semibold">Hello,</span>
                                                                                          <span className="flex justify-center items-center gap-1 font-semibold">
                                                                                                    <h2>
                                                                                                              {data?.firstName} {data?.lastName}
                                                                                                    </h2>
                                                                                                    <i className='bx bxs-hand' ></i>
                                                                                          </span>
                                                                                          <div className="flex flex-col items-center gap-1 pt-2">
                                                                                                    <h1 className="font-semibold">
                                                                                                              Welcome to,{" "}
                                                                                                              <span className={`font-semibold`}>
                                                                                                                        Mirror-LTD Music
                                                                                                              </span>
                                                                                                    </h1>
                                                                                                    {
                                                                                                              role === 'admin' ? (
                                                                                                                        <h1 className="font-semibold capitalize">
                                                                                                                                  ({role} Panel)
                                                                                                                        </h1>
                                                                                                              ) : (
                                                                                                                        <h1 className="font-semibold capitalize">
                                                                                                                                  Dashboard
                                                                                                                        </h1>
                                                                                                              )
                                                                                                    }
                                                                                          </div>
                                                                                </div>
                                                                                <hr className={`font-semibold text-white`} />
                                                                                <span className={`py-1 text-white`}>
                                                                                          <NavLink
                                                                                                    to="/profile"
                                                                                                    className={({ isActive }) =>
                                                                                                              isActive ? "text-white bg-gray-600 hover:bg-gray-600 hover:text-white flex items-center rounded-md gap-3 px-3 py-3 mt-2 duration-300" : `text-white hover:bg-gray-600 hover:text-white flex items-center rounded-md gap-3 px-3 py-3 mt-2 duration-300`
                                                                                                    }
                                                                                                    onClick={() => setIsDropdownOpen(false)}
                                                                                          >
                                                                                                    <AiOutlineUser size={18} /> Profile
                                                                                          </NavLink>
                                                                                </span>
                                                                                <span className={`py-1 text-white`}>
                                                                                          <button onClick={() => handleLogOut(data?._id)}
                                                                                                    className='text-white hover:text-white bg-gradient-to-l from-gradientSecondary to-gradientPrimary flex items-center rounded-md gap-3 px-3 py-3 duration-300 w-full'
                                                                                          >
                                                                                                    <IoMdLogOut />
                                                                                                    Sign Out
                                                                                          </button>
                                                                                </span>
                                                                      </ul>
                                                            )}
                                                  </div>
                                        </div>
                                        <Outlet />
                              </div>
                              <div className="drawer-side shadow-xl z-50">
                                        <label htmlFor="dashboard-sidebar" className="drawer-overlay"></label>
                                        <ul className={`menu p-4 overflow-y-auto h-full w-80 bg-globalColor text-base-content rounded-tr-3xl rounded-br-3xl`}>
                                                  <div className={`flex flex-col items-center gap-3 text-2xl ${role === 'admin' && 'border-b'} p-2 pb-5`}>
                                                            <Link
                                                                      to="/"
                                                                      onClick={closeDrawer}
                                                                      className={`font-semibold text-lg md:text-xl text-center flex items-center flex-col gap-2 text-white`}
                                                            >
                                                                      <img src={LOGO} alt="Logo" className="w-16 rounded-lg" /> Mirror-LTD Music
                                                                      <small className={`text-xs text-white font-normal`}>
                                                                                support@mirrorltd.com
                                                                      </small>
                                                            </Link>
                                                  </div>
                                                  {
                                                            role !== 'admin' && (
                                                                      <div className='w-full'>
                                                                                <Link to={'/new-release'}
                                                                                          onClick={closeDrawer}
                                                                                          className={`w-full btn border-none text-white bg-gradient-to-l from-gradientSecondary to-gradientPrimary`}
                                                                                >
                                                                                          <BsFillPlusCircleFill /> New Release
                                                                                </Link>
                                                                      </div>
                                                            )
                                                  }
                                                  <span className='mt-3'>
                                                            {
                                                                      menuArray.map((item) => (
                                                                                <span className="py-1" key={item.id}>
                                                                                          <NavLink
                                                                                                    className={({ isActive }) =>
                                                                                                              isActive ? "text-white bg-gray-600 hover:bg-gray-600 hover:text-white flex items-center rounded-md gap-3 px-3 py-3 md:py-4 mb-2 text-sm md:text-base duration-300" : `text-white hover:bg-gray-600 hover:text-white flex items-center rounded-md gap-3 px-3 py-3 md:py-4 mb-2 text-sm md:text-base duration-300`
                                                                                                    }
                                                                                                    to={item.link}
                                                                                                    onClick={closeDrawer}
                                                                                          >
                                                                                                    {item.icon} {item.title}
                                                                                          </NavLink>
                                                                                </span>
                                                                      ))
                                                            }
                                                  </span>
                                                  <li className="absolute bottom-5 w-72">
                                                            <button
                                                                      onClick={() => handleLogOut(user?.user?._id)}
                                                                      className={`uppercase bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white hover:text-white rounded-lg py-4 duration-300`}
                                                            >
                                                                      <IoMdLogOut /> Sign Out
                                                            </button>
                                                  </li>
                                        </ul>
                              </div>
                    </div>
          )
}