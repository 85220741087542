import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Avatar from '../../../../assets/avatar.jpg';
import GlobalLoader from '../../../../components/GlobalLoader';
import useTitle from '../../../../hooks/useTitle';
import { useRegisterAuthMutation } from '../../../../redux/features/auth/AuthApi';
import { useGetWaitingUserByIdQuery, useRejectedWaitingUserMutation } from '../../../../redux/features/WaitingUserApi';

const Fade = require("react-reveal/Fade");

export default function SingleApplications() {
          const { id } = useParams<string>();
          const navigate = useNavigate();

          const { data, isLoading } = useGetWaitingUserByIdQuery(id as string);

          const user = data?.data;

          useTitle(`${user?.firstName} ${user?.lastName}`)

          const [registerAuth, { isLoading: registerLoading, error, isSuccess, data: registerData }] =
                    useRegisterAuthMutation();
          const [rejectUser, { isLoading: rejectLoading, error: rejectError, isSuccess: rejectSuccess, data: rejectData }] = useRejectedWaitingUserMutation();

          const handleApproved = async (e: any, userDetails: any) => {
                    e.preventDefault();
                    Swal.fire({
                              title: 'Are you sure?',
                              text: "You won't be able to revert this!",
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, approve it!'
                    }).then(async (result) => {
                              if (result.isConfirmed) {
                                        try {
                                                  const data = {
                                                            firstName: userDetails?.firstName,
                                                            lastName: userDetails?.lastName,
                                                            email: userDetails?.email,
                                                            phoneNumber: userDetails?.phoneNumber,
                                                            artistName: userDetails?.artistName,
                                                            artistBio: userDetails?.artistBio,
                                                  }
                                                  await registerAuth(data);
                                        } catch (error) {
                                                  Swal.fire(
                                                            'Error!',
                                                            'Something went wrong.',
                                                            'error'
                                                  );
                                        }
                              }
                    });
          };

          const handleRejected = async (e: any, userID: string) => {
                    e.preventDefault();
                    Swal.fire({
                              title: 'Are you sure?',
                              text: "You won't be able to revert this!",
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, reject it!'
                    }).then(async (result) => {
                              if (result.isConfirmed) {
                                        try {
                                                  await rejectUser({ id: userID });
                                        } catch (error) {
                                                  Swal.fire(
                                                            'Error!',
                                                            'Something went wrong.',
                                                            'error'
                                                  );
                                        }
                              }
                    }
                    );
          };

          let tabs = ['Basic', 'Action'];
          const [activeTab, setActiveTab] = useState<number>(0);

          useEffect(() => {
                    if (error || rejectError) {
                              toast.error((error as any)?.data?.message || "Something went wrong");
                    }

                    if (isSuccess) {
                              Swal.fire({
                                        title: 'Approved!',
                                        text: (registerData as any)?.data?.message,
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1500
                              });
                              navigate("/applications");
                    }
                    if (rejectSuccess) {
                              Swal.fire({
                                        title: 'Rejected!',
                                        text: 'User has been rejected.',
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1500
                              });
                              navigate("/applications");
                    }

          }, [error, rejectError, isSuccess, rejectSuccess, rejectData, registerData, navigate]);

          return (
                    <>
                              {
                                        isLoading ?
                                                  <GlobalLoader />
                                                  : (
                                                            <Fade top distance="20px">
                                                                      <div className="md:pb-5 pb-8 rounded-md">
                                                                                <div className="card">
                                                                                          <div className='my-4'>
                                                                                                    <button className='flex items-center gap-2 cursor-pointer' onClick={() => navigate(-1)}><HiOutlineArrowNarrowLeft size={25} /><span className='text-base md:text-xl lg:text-2xl font-bold'>{user?.firstName} {user?.lastName}</span></button>
                                                                                          </div>
                                                                                          <div className='mt-2 bg-globalColor rounded-xl h-full p-4 flex flex-col justify-between md:items-start relative'>
                                                                                                    <p className={`hidden md:flex absolute top-4 right-4 py-1 px-3 ${user?.status === 'pending' ? 'bg-warning' : user?.status === 'approved' ? 'bg-gradient-to-l from-gradientSecondary to-gradientPrimary' : 'bg-error'} text-white rounded-lg capitalize`}>{user?.status}</p>
                                                                                                    <div className='flex justify-between md:justify-center md:items-center gap-3 md:gap-5 overflow-hidden'>
                                                                                                              <img src={Avatar} className='w-40 md:w-96 h-40 md:h-72 rounded-xl object-cover' alt={user?.email} />
                                                                                                              <div>
                                                                                                                        <h1 className='text-base md:text-2xl text-white font-bold'>{user?.firstName} {user?.lastName}</h1>
                                                                                                                        <p className='text-white text-sm md:text-lg'>{user?.artistName}</p>
                                                                                                                        <p className='text-white text-sm md:text-lg hidden md:flex'>
                                                                                                                                  {user?.artistBio?.length > 100 ? (
                                                                                                                                            <>
                                                                                                                                                      {user?.artistBio?.slice(0, 150)}...
                                                                                                                                            </>
                                                                                                                                  ) : (
                                                                                                                                            <>
                                                                                                                                                      {user?.artistBio}
                                                                                                                                            </>
                                                                                                                                  )}
                                                                                                                        </p>
                                                                                                              </div>
                                                                                                    </div>
                                                                                                    <div className='bg-gray-600 mt-5 rounded-xl md:hidden flex justify-between items-center py-3 px-3'>
                                                                                                              <h1 className='text-white font-bold'>Status</h1>
                                                                                                              <p className={`py-1 px-3 ${user?.status === 'pending' ? 'bg-warning' : user?.status === 'approved' ? 'bg-gradient-to-l from-gradientSecondary to-gradientPrimary' : 'bg-error'} text-white rounded-lg capitalize`}>{user?.status}</p>
                                                                                                    </div>
                                                                                          </div>
                                                                                          {
                                                                                                    registerLoading || rejectLoading ? (
                                                                                                              <GlobalLoader />
                                                                                                    ) : (
                                                                                                              <>
                                                                                                                        <div className="tabs tabs-boxed mt-5 gap-2 bg-transparent duration-300">
                                                                                                                                  {tabs.map((tab, i) => (
                                                                                                                                            <span key={i} className={`tab md:tab-lg border 
                                                                                                                                                      ${activeTab === i ? 'tab-active' : ''}
                                                                                                                                                      ${user?.status === "approved" && tab === "Action" ? "hidden" : ""}
                                                                                                                                                      `}
                                                                                                                                                      onClick={() => setActiveTab(i)}
                                                                                                                                            >
                                                                                                                                                      {tab}
                                                                                                                                            </span>
                                                                                                                                  ))}
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                                  activeTab === 0 && (
                                                                                                                                            <div className='mt-5 flex flex-col justify-start gap-3'>
                                                                                                                                                      <div className='text-start'>
                                                                                                                                                                <h1 className='font-bold text-xl md:text-2xl'>{user?.firstName} {user?.lastName}</h1>
                                                                                                                                                                <span className='text-base md:text-lg font-bold'>
                                                                                                                                                                          {user?.email}
                                                                                                                                                                </span>
                                                                                                                                                      </div>
                                                                                                                                                      <p className='mt-5'>
                                                                                                                                                                <span className='font-bold'>Apply Date: </span>{moment(user?.createdAt).format('MMM Do YYYY hh:mm:ss A')}
                                                                                                                                                      </p>
                                                                                                                                                      <p><span className='font-bold'>Artist Bio: </span>{user?.artistBio}</p>
                                                                                                                                                      <p><span className='font-bold'>Phone Number: </span>{user?.phoneNumber}</p>
                                                                                                                                                      <p><span className='font-bold'>Registered at: </span>{moment(user?.createdAt).format('MMM Do YYYY, h:mm:ss a')}</p>
                                                                                                                                            </div>
                                                                                                                                  )
                                                                                                                        }
                                                                                                                        {
                                                                                                                                  activeTab === 1 && (
                                                                                                                                            <div className="mt-5">
                                                                                                                                                      <div className='flex items-center gap-3 mb-3 border p-3 rounded-lg'>
                                                                                                                                                                <span className='font-bold'>Status:
                                                                                                                                                                          <select
                                                                                                                                                                                    className={`ml-2 select select-bordered select-sm ${user?.status === "approved" && "btn-disabled btn-success text-white"} ${user?.status === "rejected" && "btn-disabled btn-error text-white"}`}
                                                                                                                                                                                    defaultValue={
                                                                                                                                                                                              user?.status === "rejected"
                                                                                                                                                                                                        ? "Rejected"
                                                                                                                                                                                                        : user?.status === "approved"
                                                                                                                                                                                                                  ? "Approved"
                                                                                                                                                                                                                  : "Pending"
                                                                                                                                                                                    }
                                                                                                                                                                                    onChange={(e) => {
                                                                                                                                                                                              if (e.target.value === "Approved") {
                                                                                                                                                                                                        handleApproved(e, user);
                                                                                                                                                                                              } else if (e.target.value === "Rejected") {
                                                                                                                                                                                                        handleRejected(e, user?._id);
                                                                                                                                                                                              } else {
                                                                                                                                                                                                        return;
                                                                                                                                                                                              }
                                                                                                                                                                                    }}
                                                                                                                                                                          >
                                                                                                                                                                                    <option disabled selected>
                                                                                                                                                                                              Pending
                                                                                                                                                                                    </option>
                                                                                                                                                                                    <option>Approved</option>
                                                                                                                                                                                    <option>Rejected</option>
                                                                                                                                                                          </select>
                                                                                                                                                                </span>
                                                                                                                                                      </div>
                                                                                                                                            </div>
                                                                                                                                  )
                                                                                                                        }
                                                                                                              </>
                                                                                                    )
                                                                                          }
                                                                                </div>
                                                                      </div>
                                                            </Fade>
                                                  )
                              }
                    </>
          )
}
