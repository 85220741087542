import { useState } from 'react';
import { HiArrowLongLeft } from 'react-icons/hi2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import GlobalLoader from '../../../../components/GlobalLoader';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import useTitle from '../../../../hooks/useTitle';
import { useGetUserByIdQuery } from '../../../../redux/features/auth/AuthApi';
import { useGetAllReleasesByArtistQuery } from '../../../../redux/features/SongApi';

export default function UserSongs() {
          useTitle('All songs');
          useScrollToTop();

          const { id } = useParams();
          const navigate = useNavigate();

          const { data: artistData, isLoading: artistLoading } = useGetUserByIdQuery(id);

          const artist = artistData?.data;


          const [status, setStatus] = useState<string>('');

          const { data, isLoading } = useGetAllReleasesByArtistQuery({ id, status: status });

          const handleStatusChange = (e: any) => {
                    setStatus(e.target.value);
          }

          const releases = data?.data;

          if (isLoading || artistLoading) {
                    return <GlobalLoader />
          }

          return (
                    <div className="md:pb-5 pb-8 rounded-md">
                              <div className="title my-2 mb-10">
                                        <h3 className="text-2xl font-bold flex items-center gap-2"><button className='flex items-center gap-2 cursor-pointer' onClick={() => navigate(-1)}><HiArrowLongLeft size={25} /></button> All songs list</h3>
                                        <span>
                                                  You can view all your songs here with their status
                                        </span><br />
                                        <span className='font-bold'>Name: <span className='text-error'>{artist?.firstName} {artist?.lastName}</span></span> <br />
                                        <span className='font-bold'>Email: <span className='text-error'>{artist?.email}</span></span>
                              </div>
                              <div className='flex justify-center md:justify-end mt-10 mb-5 w-full md:w-[450px] mx-auto'>
                                        <div className="name border rounded-md p-3 relative flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-poppins">Filter by status</h3>
                                                  </div>
                                                  <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                            <select
                                                                      value={status}
                                                                      onChange={handleStatusChange}
                                                                      className={`text-sm form-control outline-none w-full bg-transparent`}
                                                            >
                                                                      <option value="">All</option>
                                                                      <option value="pending">Pending</option>
                                                                      <option value="approved">Approved</option>
                                                                      <option value="rejected">Rejected</option>
                                                                      <option value="takedown">Takedown</option>
                                                            </select>
                                                  </div>
                                        </div>
                              </div>
                              {
                                        releases?.length > 0 ? (
                                                  <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5'>
                                                            {
                                                                      releases?.map((release: any) => (
                                                                                <Link to={`/all-artists/songs/${release._id}`} key={release._id} className={`relative w-full rounded-xl shadow-md flex flex-col group`}>
                                                                                          <div className="w-full overflow-hidden rounded-xl glass">
                                                                                                    <img
                                                                                                              className="w-full h-32 md:h-48 lg:h-52 object-cover md:group-hover:scale-110 duration-300"
                                                                                                              src={release?.albumCover}
                                                                                                              alt={release?.releaseTitle}
                                                                                                              loading='lazy'
                                                                                                    />
                                                                                                    <span className={`badge badge-xs md:badge-sm border-none ${release?.status === 'pending' ? 'bg-warning' : release?.status === 'approved' ? 'bg-gradient-to-l from-gradientSecondary to-gradientPrimary' : 'bg-error'} absolute top-2 right-2 opacity-100 transition-opacity text-white text-xs font-semibold capitalize`}>
                                                                                                              {release?.status}
                                                                                                    </span>
                                                                                          </div>
                                                                                          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#ffffff00] to-black transition-opacity duration-300 ease-in-out rounded-xl"></div>
                                                                                          <div className="absolute bottom-0 left-0 p-4 text-white transition-opacity duration-300 ease-in-out rounded-xl">
                                                                                                    <h3 className="text-base md:text-lg font-bold md:hidden">
                                                                                                              {release?.releaseTitle?.length > 10 ? release?.releaseTitle?.slice(0, 10) + '...' : release?.releaseTitle}
                                                                                                    </h3>
                                                                                                    <h3 className="text-base md:text-lg font-bold hidden md:block">
                                                                                                              {release?.releaseTitle}
                                                                                                    </h3>
                                                                                                    <p className='uppercase text-xs md:text-sm font-semibold'>{release?.displayArtist[0]?.artistName}</p>
                                                                                          </div>
                                                                                </Link>
                                                                      ))
                                                            }
                                                  </div>
                                        ) : (
                                                  <div className="card flex flex-col justify-center items-center mx-auto shadow-md w-full md:w-[450px] mt-24">
                                                            <div className="card-body">
                                                                      <h1 className='text-center text-xl text-red-600'>
                                                                                Oops! No songs found
                                                                      </h1>
                                                            </div>
                                                  </div>
                                        )
                              }
                    </div>
          )
}
