import React, { useEffect } from 'react'
import { authUserInterface } from '../../interfaces/UserInterface';
import useAuth from '../../hooks/useAuth';
import HomeLoader from '../../components/HomeLoader';
import UserDashboard from './UserDashboard/UserDashboard';
import AdminDashboard from './AdminDashboard/AdminDashboard';

export default function Index() {
          const {
                    updatedUser,
                    refetch,
                    isLoading,
          } = useAuth<authUserInterface | any>({});

          const role = updatedUser?.role;

          useEffect(() => {
                    if (isLoading) return;
                    if (updatedUser) {
                              refetch();
                    }
          }, [updatedUser, isLoading, refetch]);

          if (isLoading) {
                    return <HomeLoader />
          }

          return (
                    <>
                              {
                                        role === "user" ? <UserDashboard updatedUser={updatedUser} /> : <AdminDashboard updatedUser={updatedUser} />
                              }
                    </>
          )
}
