import {
  LoginAuthState,
  UserInterface,
} from "../../../interfaces/UserInterface";
import { baseApi } from "../../baseApi";

type UserType = {
  _id?: number;
  role?: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  phoneNumber: string;
  artistName?: string;
  artistBio: string;
};

type LoginType = {
  email?: string;
  password?: string;
  reCaptchaValue?: string;
};

type getUserType = {
  success: boolean;
  data: UserInterface;
};

type paginationType = {
  page: number;
  limit: number;
  role: string;
};

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOnlyUsers: builder.query({
      query: () => `/users/only-users`,
      providesTags: ["user"],
    }),
    getUserById: builder.query({
      query: (id) => `/users/user-by-id/${id}`,
      providesTags: ["user"],
    }),
    getOnlyAdmins: builder.query({
      query: () => "/users/only-admins",
      providesTags: ["user"],
    }),
    registerAuth: builder.mutation<UserType, UserType>({
      query: (body) => ({
        url: "/users/signup",
        method: "POST",
        body,
      }),
    }),
    loginAuth: builder.mutation<LoginAuthState, LoginType>({
      query: (body) => ({
        url: "/users/login",
        method: "POST",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    logOutAuth: builder.mutation({
      query: (body) => ({
        url: "/users/logout",
        method: "POST",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: "/users/update-profile",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    changeProfilePicture: builder.mutation({
      query: (body) => ({
        url: "/users/change-profile-picture",
        method: "POST",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    changeUserRole: builder.mutation({
      query: (body) => ({
        url: "/users/change-user-role",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    getUser: builder.query<getUserType, string>({
      query: (id) => `/users/me/${id}`,
      providesTags: ["user"],
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: "/users/change-password",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "/users/reset-password",
        method: "POST",
        body,
      }),
    }),
    changePasswordWithoutOldPwd: builder.mutation({
      query: (body) => ({
        url: "/users/change-password/new",
        method: "POST",
        body,
      }),
    }),
    verificationMail: builder.mutation({
      query: (body) => ({
        url: "/users/verify-mail",
        method: "POST",
        body,
      }),
    }),
    changeUserStatus: builder.mutation({
      query: (body) => ({
        url: "/users/change-user-status",
        method: "POST",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    addBalance: builder.mutation({
      query: (body) => ({
        url: `/wallets/add-balance`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    updatePercentage: builder.mutation({
      query: (body) => ({
        url: `/wallets/update-percentage`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    getAllUsersForAdmin: builder.query<any, paginationType>({
      query: (query: any) =>
        `/users/admin?role=${query.role}&limit=${query.limit}&page=${query.page}`,
      providesTags: ["user"],
    }),
    deleteUserForAdmin: builder.mutation({
      query: (id: string) => ({
        url: `/users/admin/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["user"],
    }),
    getAllUsers: builder.query({
      query: () => "/admin/users",
      providesTags: ["user"],
    }),
    addAnAdmin: builder.mutation({
      query: (body) => ({
        url: `/users/add-an-admin`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["user"],
    }),
  }),
});

export const {
  useRegisterAuthMutation,
  useLoginAuthMutation,
  useLogOutAuthMutation,
  useUpdateProfileMutation,
  useChangeProfilePictureMutation,
  useChangeUserRoleMutation,
  useGetUserQuery,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useChangePasswordWithoutOldPwdMutation,
  useVerificationMailMutation,
  useGetOnlyUsersQuery,
  useGetOnlyAdminsQuery,
  useChangeUserStatusMutation,
  useGetAllUsersForAdminQuery,
  useDeleteUserForAdminMutation,
  useGetAllUsersQuery,
  useGetUserByIdQuery,
  useAddBalanceMutation,
  useAddAnAdminMutation,
  useUpdatePercentageMutation,
} = authApi;
