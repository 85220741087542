import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { authUserInterface } from "../interfaces/UserInterface";
import { useLogOutAuthMutation } from "../redux/features/auth/AuthApi";
import { logout } from "../redux/features/auth/AuthSlice";
import { useAppDispatch } from "../redux/store";

type Props = { children: React.ReactNode };

const RequireAuth = ({ children }: Props) => {
          const { user } = useAuth<authUserInterface | any>({});
          const dispatch = useAppDispatch();
          const [logOut] = useLogOutAuthMutation();
          const location = useLocation();

          if (!user?.token) {
                    return <Navigate to={"/login"} replace state={{ from: location }} />;
          }

          if (user?.user?.status === false) {
                    dispatch(logout());
                    logOut({ id: user?.user?._id });
          }

          return <>{children}</>;
};

export default RequireAuth;