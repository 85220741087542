import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { PiPassword } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import MiniTitle from '../../../components/MiniTitle';
import useAuth from '../../../hooks/useAuth';
import useTitle from '../../../hooks/useTitle';
import { authUserInterface } from '../../../interfaces/UserInterface';
import { useChangePasswordMutation } from '../../../redux/features/auth/AuthApi';
import { logout } from '../../../redux/features/auth/AuthSlice';
import { useAppDispatch } from '../../../redux/store';

export default function ChangePassword() {
          useTitle('Change Password');

          const { updatedUser, setUser } = useAuth<authUserInterface | any>({});
          const [changePassword, { data, isLoading, isSuccess, error }] =
                    useChangePasswordMutation();

          const dispatch = useAppDispatch();
          const navigate = useNavigate();

          const [isShow, setIsShow] = useState<boolean>(false);

          const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    const formData = new FormData(e.currentTarget);
                    const oldPassword = formData.get('oldPassword');
                    const newPassword = formData.get('newPassword');
                    const confirmNewPassword = formData.get('confirmNewPassword');
                    if (!oldPassword) return toast.error('Old Password is required');
                    if (!newPassword) return toast.error('New Password is required');
                    if (!confirmNewPassword) return toast.error('Confirm New Password is required');
                    if (newPassword !== confirmNewPassword) return toast.error('New Password and Confirm New Password must be same');
                    if (oldPassword && newPassword && confirmNewPassword) {
                              changePassword({
                                        email: updatedUser?.email,
                                        oldPassword,
                                        newPassword,
                              })
                    }
          }

          useEffect(() => {
                    if (error) toast.error((error as any)?.data?.message);
                    if (isSuccess) {
                              toast.success(
                                        data?.message + " Now you may login using the new password"
                              );
                              setUser(null);
                              dispatch(logout());
                              navigate("/login");
                    }
          }, [isSuccess, data, error, dispatch, setUser, navigate]);

          return (
                    <div className='py-4 md:py-5 rounded-xl'>
                              <MiniTitle title='Change Password' />
                              <form onSubmit={handleSubmit} className="flex flex-col space-y-4 w-full md:w-1/2 lg:w-1/3">
                                        <div className="w-full">
                                                  <label htmlFor="oldPassword" className="text-xs font-semibold px-1 text-black flex items-center gap-1 mb-1">Old Password <span className='text-gradientPrimary'>*</span></label>
                                                  <div className="flex relative">
                                                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center text-black"><PiPassword /></div>
                                                            <input
                                                                      type={isShow ? 'text' : 'password'}
                                                                      className="bg-transparent w-full -ml-10 pl-10 pr-3 input input-bordered focus:border-gradientPrimary text-black md:py-7"
                                                                      placeholder="Enter old password"
                                                                      autoComplete='off'
                                                                      name='oldPassword'
                                                            />
                                                            <div
                                                                      className="eye absolute right-4 top-[28%] md:top-[33%] cursor-pointer z-10 select-none"
                                                                      onClick={() => setIsShow((state) => !state)}
                                                            >
                                                                      {isShow ? <FaRegEye size={21} className='text-black' /> : <FaRegEyeSlash size={21} className='text-black' />}
                                                            </div>
                                                  </div>
                                        </div>
                                        <div className="w-full">
                                                  <label htmlFor="newPassword" className="text-xs font-semibold px-1 text-black flex items-center gap-1 mb-1">New Password <span className='text-gradientPrimary'>*</span></label>
                                                  <div className="flex">
                                                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center text-black"><PiPassword /></div>
                                                            <input
                                                                      type={isShow ? 'text' : 'password'}
                                                                      className="bg-transparent w-full -ml-10 pl-10 pr-3 input input-bordered focus:border-gradientPrimary text-black md:py-7"
                                                                      placeholder="Enter new password"
                                                                      autoComplete='off'
                                                                      name='newPassword'
                                                            />
                                                  </div>
                                        </div>
                                        <div className="w-full">
                                                  <label htmlFor="confirmNewPassword" className="text-xs font-semibold px-1 text-black flex items-center gap-1 mb-1">Confirm New Password <span className='text-gradientPrimary'>*</span></label>
                                                  <div className="flex">
                                                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center text-black"><PiPassword /></div>
                                                            <input
                                                                      type={isShow ? 'text' : 'password'}
                                                                      className="bg-transparent w-full -ml-10 pl-10 pr-3 input input-bordered focus:border-gradientPrimary text-black md:py-7"
                                                                      placeholder="Enter confirm new password"
                                                                      autoComplete='off'
                                                                      name='confirmNewPassword'
                                                            />
                                                  </div>
                                        </div>
                                        <div className='flex justify-end'>
                                                  {isLoading ? (
                                                            <button className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary focus:bg-gradientPrimary text-white rounded-lg md:px-6 font-semibold" disabled>
                                                                      <PulseLoader color="#fff" size={6} />
                                                            </button>
                                                  ) : (
                                                            <button className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary focus:bg-gradientPrimary text-white rounded-lg md:px-6 font-semibold">Update</button>
                                                  )}
                                        </div>
                              </form>
                    </div>
          )
}
