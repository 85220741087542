/* eslint-disable no-sequences */
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import { AiOutlinePlus, AiOutlineQuestionCircle } from 'react-icons/ai';
import { BsImage, BsMusicNote } from 'react-icons/bs';
import { FaRegLightbulb } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import AlbumCoverGuideline from '../../../../components/AlbumCoverGuideline';
import RemixVersionGuideline from '../../../../components/RemixVersionGuideline';
import { language, primaryGenre, secondaryGenre, services, youtubeContentID } from '../../../../configs/global';
import useAuth from '../../../../hooks/useAuth';
import useTitle from '../../../../hooks/useTitle';
import { authUserInterface } from '../../../../interfaces/UserInterface';
import { CustomError } from '../../../../redux/baseApi';
import { useUpdateSongMutation, useUploadSongMutation } from '../../../../redux/features/SongApi';
import ScrollButton from '../../../../shared/ScrollButton';

type DisplayArtist = {
          type: string;
          artistName: string;
          spotifyURL: string;
          appleMusicURL: string;
};

type Credits = {
          type: string;
          artistName: string;
};

type FormData = {
          releaseTitle: string;
          remixVersionInfo: string;
          language: string;
          releaseType: string;
          albumCover: string[];
          primaryGenre: string;
          secondaryGenre: string;
          displayArtist: DisplayArtist[];
          credits: Credits[];
          recordLabelName: string;
          audioFile: string[];
          isExplicit: boolean;
          isInstrumental: boolean;
          services: string[];
          youtubeContentIDTerms: string[];
          termsOne: boolean;
          termsTwo: boolean;
          termsThree: boolean;
          termsFour: boolean;
};

interface NewReleaseProps {
          song?: any;
}

export default function NewRelease({ song }: NewReleaseProps) {
          useTitle('New Release');

          const {
                    updatedUser,
                    isLoading,
          } = useAuth<authUserInterface | any>({});

          const navigate = useNavigate();

          const [uploadSong, { data: uploadedSong, isSuccess: uploadedSuccess, isLoading: uploadLoading, error: uploadedError }] = useUploadSongMutation();
          const [updateSong, { data: updatedSong, isSuccess: updatedSuccess, isLoading: updatedLoading, error: updatedError }] = useUpdateSongMutation();

          const { register, handleSubmit, watch, control, reset, setValue } = useForm<FormData>({
                    defaultValues: {
                              releaseTitle: '',
                              remixVersionInfo: '',
                              language: 'english',
                              releaseType: 'single',
                              albumCover: [],
                              primaryGenre: '',
                              secondaryGenre: '',
                              displayArtist: song?.displayArtist ? [] : [{ type: 'primaryArtist', artistName: '', spotifyURL: '', appleMusicURL: '' }],
                              credits: song?.credits ? [] : [{ type: 'producer', artistName: '' }],
                              recordLabelName: '',
                              audioFile: [],
                              isExplicit: false,
                              isInstrumental: false,
                              services: [],
                              youtubeContentIDTerms: [],
                              termsOne: false,
                              termsTwo: false,
                              termsThree: false,
                              termsFour: false,
                    },
          });

          const [isImageError, setIsImageError] = useState<boolean>(false);
          const [albumCoverView, setAlbumCoverView] = useState<string>("");
          const [isAudioFileView, setIsAudioFileView] = useState<string>("");
          const [isExplicit, setIsExplicit] = useState<boolean>(false);
          const [isInstrumental, setIsInstrumental] = useState<boolean>(false);
          const [isYTContentID, setIsYTContentID] = useState<boolean>(false);

          const {
                    fields: displayArtistFields,
                    append: displayArtistAppend,
                    remove: displayArtistRemove,
          } = useFieldArray({ control, name: 'displayArtist' });

          const {
                    fields: creditFields,
                    append: creditAppend,
                    remove: creditRemove,
          } = useFieldArray({ control, name: 'credits' });

          const handleSubmitSong = handleSubmit(async (formDataInput) => {
                    if (!song && !formDataInput?.releaseTitle) {
                              return toast.error('Please enter the release title');
                    }

                    if (!song && !formDataInput?.releaseType) {
                              return toast.error('Please enter the release type');
                    }

                    if (!song && !formDataInput?.albumCover[0]) {
                              return toast.error('Please upload the album cover');
                    }

                    if (!song && !formDataInput?.primaryGenre) {
                              return toast.error('Please select the primary genre');
                    }

                    if (!formDataInput?.displayArtist[0]?.artistName) {
                              return toast.error('Please enter the display artist details');
                    }

                    if (!song && !formDataInput?.recordLabelName) {
                              return toast.error('Please enter the record label name');
                    }

                    if (!song && !formDataInput?.audioFile[0]) {
                              return toast.error('Please upload the audio file');
                    }

                    const ContentIDLength = formDataInput?.youtubeContentIDTerms;

                    if (!song && isYTContentID === true && ContentIDLength?.length !== 9) {
                              return toast.error('Please accept all the YouTube Content ID terms');
                    }

                    if (!song && (!formDataInput?.termsOne || !formDataInput?.termsTwo || !formDataInput?.termsThree || !formDataInput?.termsFour)) {
                              return toast.error('Please accept all the terms and conditions');
                    }

                    const displayArtist = formDataInput?.displayArtist;
                    const credits = formDataInput?.credits;

                    const stringifyData = {
                              displayArtist,
                              credits,
                    }

                    const formData = new FormData();

                    formData.append('email', updatedUser?.email || '')
                    formData.append('releaseTitle', formDataInput?.releaseTitle || '');
                    formData.append('remixVersionInfo', formDataInput?.remixVersionInfo || '');
                    formData.append('language', formDataInput.language || '');
                    formData.append('releaseType', formDataInput.releaseType || '');
                    formData.append('files', formDataInput.albumCover[0]);
                    formData.append('primaryGenre', formDataInput.primaryGenre || '');
                    formData.append('secondaryGenre', formDataInput.secondaryGenre || '');
                    formData.append('stringifyData', JSON.stringify(stringifyData) || '');
                    formData.append('recordLabelName', formDataInput.recordLabelName || '');
                    formData.append('files', formDataInput.audioFile[0]);
                    formData.append('isExplicit', isExplicit.toString());
                    formData.append('isInstrumental', isInstrumental.toString());
                    formData.append('services', JSON.stringify(formDataInput.services) || '');
                    formData.append('owner', updatedUser?._id || '');

                    try {
                              if (song) {
                                        await updateSong({
                                                  id: song?._id,
                                                  data: formData,
                                        });
                              } else {
                                        await uploadSong(formData);
                              }
                    } catch (error) {
                              console.log(error);
                    }
          })

          watch((data) => {
                    if (data?.albumCover !== undefined && data?.albumCover?.length > 0) {
                              const reader = new FileReader();
                              reader?.readAsDataURL(data?.albumCover[0] as any);
                              reader.onloadend = (e: ProgressEvent<FileReader>) => {
                                        const img = new Image();
                                        img.src = e?.target?.result as string;
                                        img.onload = () => {
                                                  if (img.width !== 3000 && img.height !== 3000) {
                                                            setIsImageError(true);
                                                  }

                                                  if (img.width === 3000 && img.height === 3000) {
                                                            setIsImageError(false);
                                                  }
                                        }
                                        setAlbumCoverView(reader?.result as string);
                              };
                    }

                    if (data?.audioFile !== undefined && data?.audioFile?.length > 0) {
                              const reader = new FileReader();
                              reader?.readAsDataURL(data?.audioFile[0] as any);
                              const audio = data?.audioFile[0] as any;
                              setIsAudioFileView(audio?.name as string);
                    }
          });

          useEffect(() => {
                    if (uploadedSuccess) {
                              Swal.fire({
                                        title: 'Success',
                                        text: uploadedSong?.message || 'Song uploaded successfully',
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 2000,
                              })

                              setAlbumCoverView("");
                              setIsAudioFileView("");
                              setIsExplicit(false);
                              setIsInstrumental(false);
                              creditRemove();
                              displayArtistRemove();
                              setIsYTContentID(false);
                              reset();
                    }

                    if (uploadedError) {
                              toast.error((uploadedError as CustomError)?.data?.message);
                    }
          }, [uploadedSuccess, uploadedError, reset, uploadedSong, creditRemove, displayArtistRemove]);

          useEffect(() => {
                    if (updatedSuccess) {
                              Swal.fire({
                                        title: 'Success',
                                        text: updatedSong?.message || 'Song updated successfully',
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 2000,
                              })
                              navigate(-1);
                    }

                    if (updatedError) {
                              toast.error((updatedError as CustomError)?.data?.message);
                    }
          }, [updatedSuccess, updatedError, reset, updatedSong, creditRemove, displayArtistRemove, navigate]);

          useEffect(() => {
                    if (song) {
                              setValue('releaseTitle', song?.releaseTitle);
                              setValue('remixVersionInfo', song?.remixVersionInfo);
                              setValue('language', song?.language);
                              setValue('primaryGenre', song?.primaryGenre);
                              setValue('secondaryGenre', song?.secondaryGenre);
                              setValue('recordLabelName', song?.recordLabelName);
                              setValue('isExplicit', song?.isExplicit?.toString());
                              setValue('isInstrumental', song?.isInstrumental?.toString());
                              setValue('services', song?.services);
                              setValue('youtubeContentIDTerms', song?.youtubeContentIDTerms);

                              const displayArtist = song?.displayArtist;
                              const credits = song?.credits;

                              displayArtistRemove();
                              displayArtist?.map((item: any) => (
                                        displayArtistAppend({
                                                  type: item?.type,
                                                  artistName: item?.artistName,
                                                  spotifyURL: item?.spotifyURL,
                                                  appleMusicURL: item?.appleMusicURL,
                                        })
                              ));

                              creditRemove();
                              credits?.map((item: any) => (
                                        creditAppend({
                                                  type: item?.type,
                                                  artistName: item?.artistName,
                                        })
                              ));
                    }
          }, [creditAppend, creditRemove, displayArtistAppend, displayArtistRemove, setValue, song]);

          return (
                    <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
                              <div className="title my-2 mb-10">
                                        <h3 className="text-2xl font-bold">
                                                  {song ? 'Edit Release' : 'New Release'}
                                        </h3>
                                        <span>
                                                  {song ? 'You can edit your song here with all the details' : 'You can upload your song here with all the details'}
                                        </span>
                              </div>

                              <form
                                        encType="multipart/form-data"
                                        method="post"
                                        onSubmit={handleSubmitSong}>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">Release Title <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                            <input
                                                                      type="text"
                                                                      className={`text-sm form-control outline-none w-full`}
                                                                      placeholder='Release Title'
                                                                      {...register("releaseTitle")}
                                                            />
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold flex items-center gap-2">Remix or Version (Optional)
                                                                      <label
                                                                                htmlFor="remix-version-guideline"
                                                                                className="cursor-pointer rounded-full"
                                                                      >
                                                                                <AiOutlineQuestionCircle size={16} />
                                                                      </label>
                                                            </h3>
                                                  </div>
                                                  <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                            <input
                                                                      type="text"
                                                                      className={`text-sm form-control outline-none w-full`}
                                                                      placeholder='Remix or Version (Optional)'
                                                                      {...register("remixVersionInfo")}
                                                            />
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">Language of the release <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                            <select
                                                                      className={`text-sm form-control outline-none w-full`}
                                                                      {...register("language")}
                                                            >
                                                                      {
                                                                                language.map((lang) => (
                                                                                          <option value={lang.value} key={lang.id} selected={lang.selected}>{lang.name}</option>
                                                                                ))
                                                                      }
                                                            </select>
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">Release type <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                            <select
                                                                      className={`text-sm form-control outline-none w-full`}
                                                                      {...register("releaseType")}
                                                            >
                                                                      <option value={'single'} selected>Single</option>
                                                            </select>
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">Primary Genre <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                            <select
                                                                      className={`text-sm form-control outline-none w-full`}
                                                                      {...register("primaryGenre")}
                                                            >
                                                                      {primaryGenre.map((genre) => (
                                                                                <option value={genre.value} key={genre.id}>{genre.name}</option>
                                                                      )
                                                                      )}
                                                            </select>
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">Secondary Genre (optional)</h3>
                                                  </div>
                                                  <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                            <select
                                                                      className={` text-sm form-control outline-none w-full`}
                                                                      {...register("secondaryGenre")}
                                                            >
                                                                      {secondaryGenre.map((genre) => (
                                                                                <option value={genre.value} key={genre.id}>{genre.name}</option>
                                                                      )
                                                                      )}
                                                            </select>
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">Display Artist <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  {
                                                            displayArtistFields.map((item, index) => (
                                                                      <div key={item.id}>
                                                                                <div className={`input-group flex w-full md:w-1/3 md:pr-5 items-center my-2 rounded-md mt-2`}>
                                                                                          <select style={{ borderRadius: '6px' }} className='select select-bordered text-sm rounded-md w-full'
                                                                                                    {...register(`displayArtist.${index}.type`)}
                                                                                                    defaultValue={item.type}
                                                                                          >
                                                                                                    <option value="primaryArtist">Primary Artist</option>
                                                                                          </select>
                                                                                </div>
                                                                                <div className='flex flex-col md:flex-row items-center gap-3'>
                                                                                          <input
                                                                                                    {...register(`displayArtist.${index}.artistName`)}
                                                                                                    type="text"
                                                                                                    className={`input input-bordered rounded-md text-sm form-control outline-none w-full`}
                                                                                                    placeholder="Artist Name"
                                                                                                    defaultValue={item.artistName}
                                                                                          />
                                                                                          <input
                                                                                                    {...register(`displayArtist.${index}.spotifyURL`)}
                                                                                                    type="text"
                                                                                                    className={`input input-bordered rounded-md text-sm form-control outline-none w-full`}
                                                                                                    placeholder="Spotify URL"
                                                                                                    defaultValue={item.spotifyURL}
                                                                                          />
                                                                                          <input
                                                                                                    {...register(`displayArtist.${index}.appleMusicURL`)}
                                                                                                    type="text"
                                                                                                    className={`input input-bordered rounded-md text-sm form-control outline-none w-full`}
                                                                                                    placeholder="Apple Music URL"
                                                                                                    defaultValue={item.appleMusicURL}
                                                                                          />
                                                                                          <div>
                                                                                                    {index > 0 && (<button type='button' className='btn btn-xs btn-error text-white rounded-full' onClick={() => displayArtistRemove(index)}>x</button>)}
                                                                                          </div>
                                                                                </div>
                                                                      </div>
                                                            ))
                                                  }
                                                  <div className='mt-3'>
                                                            <button type='button' className='flex items-center text-error' onClick={() => displayArtistAppend({
                                                                      type: 'primaryArtist',
                                                                      artistName: '',
                                                                      spotifyURL: '',
                                                                      appleMusicURL: '',
                                                            })}><AiOutlinePlus /><span className='md:hover:underline duration-300 bg-transparent'>Add more</span></button>
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">Credits (Lyricist & Composer required)</h3>
                                                  </div>
                                                  {
                                                            creditFields.map((item, index) => (
                                                                      <div key={item.id}>
                                                                                <div className={`input-group flex w-full md:w-1/3 md:pr-5 items-center my-2 rounded-md mt-2`}>
                                                                                          <select style={{ borderRadius: '6px' }} className='select select-bordered text-sm rounded-md w-full'
                                                                                                    {...register(`credits.${index}.type`)}
                                                                                                    defaultValue={item.type}
                                                                                          >
                                                                                                    <option value="producer">Producer</option>
                                                                                                    <option value="remixer">Remixer</option>
                                                                                                    <option value="composer">Composer</option>
                                                                                                    <option value="lyricist">Lyricist</option>
                                                                                                    <option value="featuring">Featuring</option>
                                                                                          </select>
                                                                                </div>
                                                                                <div className='flex flex-col md:flex-row items-center gap-3'>
                                                                                          <input
                                                                                                    {...register(`credits.${index}.artistName`)}
                                                                                                    type="text"
                                                                                                    className={`input input-bordered rounded-md text-sm form-control outline-none w-full md:w-1/3`}
                                                                                                    placeholder="Artist Name"
                                                                                                    defaultValue={item.artistName}
                                                                                          />
                                                                                          <div>
                                                                                                    {index > 0 && (<button type='button' className='btn btn-xs btn-error text-white rounded-full' onClick={() => creditRemove(index)}>x</button>)}
                                                                                          </div>
                                                                                </div>
                                                                      </div>
                                                            ))
                                                  }
                                                  <div className='mt-3'>
                                                            <button type='button' className='flex items-center text-error' onClick={() => creditAppend({
                                                                      type: 'producer',
                                                                      artistName: '',
                                                            })}><AiOutlinePlus /><span className='md:hover:underline duration-300 bg-transparent'>Add more</span></button>
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">Record Label Name <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                            <input
                                                                      type="text"
                                                                      className={`text-sm form-control outline-none w-full`}
                                                                      placeholder={'Record Label Name'}
                                                                      {...register("recordLabelName")}
                                                            />
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">Explicit lyrics <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  <div className={`input-group flex flex-col items-start my-2 border p-3 rounded-md mt-2`}>
                                                            <div className='flex md:px-3 items-center gap-2 my-3'>
                                                                      <input type="radio" className="radio radio-xs"
                                                                                value={"false"}
                                                                                defaultChecked
                                                                                {...register(`isExplicit`)}
                                                                                onClick={() => setIsExplicit(false)}
                                                                      />
                                                                      <p className='text-sm text-gray-500'>No</p>
                                                            </div>
                                                            <div className='flex md:px-3 items-center gap-2 my-3'>
                                                                      <input type="radio" className="radio radio-xs"
                                                                                value={"true"}
                                                                                {...register(`isExplicit`)}
                                                                                onClick={() => setIsExplicit(true)}
                                                                      />
                                                                      <p className='text-sm text-gray-500'>Yes</p>
                                                            </div>
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">Instrumental? <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  <div className={`input-group flex flex-col items-start my-2 border p-3 rounded-md mt-2`}>
                                                            <div className='flex md:px-3 items-center gap-2 my-3'>
                                                                      <input type="radio" className="radio radio-xs"
                                                                                value={"false"}
                                                                                defaultChecked
                                                                                {...register(`isInstrumental`)}
                                                                                onChange={() => setIsInstrumental(false)}
                                                                      />
                                                                      <p className='text-sm text-gray-500'>Yes - This song contains lyrics</p>
                                                            </div>
                                                            <div className='flex md:px-3 items-center gap-2 my-3'>
                                                                      <input type="radio" className="radio radio-xs"
                                                                                value={"true"}
                                                                                {...register(`isInstrumental`)}
                                                                                onChange={() => setIsInstrumental(true)}
                                                                      />
                                                                      <p className='text-sm text-gray-500'>This song is instrumental and contains no lyrics</p>
                                                            </div>
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">Services <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  <div className={`input-group grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-2 items-center my-2 border p-3 rounded-md mt-2`}>
                                                            {
                                                                      services.map((service) => (
                                                                                <div className='flex md:px-3 items-center gap-2 my-3' key={service.id}>
                                                                                          <input type="checkbox" className="checkbox checkbox-sm"
                                                                                                    {...register("services")}
                                                                                                    value={service.value}
                                                                                                    onChange={() => {
                                                                                                              if (service.value === 'youtubeContentID') {
                                                                                                                        setIsYTContentID(!isYTContentID);
                                                                                                              }
                                                                                                    }}
                                                                                                    defaultChecked={service.selected}
                                                                                          />
                                                                                          <p className='text-sm text-gray-500'>{service.name}</p>
                                                                                </div>
                                                                      ))
                                                            }
                                                  </div>
                                        </div>
                                        {
                                                  isYTContentID === true && (
                                                            <div className="name border rounded p-3 relative mt-10 flex-1">
                                                                      <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                <h3 className="text-sm font-bold">YouTube Content ID <span className='text-red-600'>*</span></h3>
                                                                      </div>
                                                                      <p className='text-sm pt-2 font-bold'>Important stuff that YouTube takes seriously</p>
                                                                      <p className='text-xs pt-2 font-bold'>Does your single contain any audio (beats, loops, samples, video game sounds, other peoples' music...) that you didn't create yourself? If so, this single is unfortunately ineligible for YouTube Content ID. Not only that, but all of the following must be true as well:</p>
                                                                      <div>
                                                                                {
                                                                                          youtubeContentID.map((content) => (
                                                                                                    <div className='flex md:px-3 items-center gap-2 my-3' key={content.id}>
                                                                                                              <input type="checkbox" className="checkbox checkbox-xs"
                                                                                                                        value={content.value as unknown as string}
                                                                                                                        {...register("youtubeContentIDTerms")}
                                                                                                              />
                                                                                                              <p className='text-xs font-semibold'>{content.name}</p>
                                                                                                    </div>
                                                                                          ))
                                                                                }
                                                                      </div>
                                                            </div>
                                                  )
                                        }
                                        <div className="name border rounded p-3 relative mt-10">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold flex items-center gap-2">
                                                                      Album Cover <span className='text-red-600'>*</span>
                                                                      <label
                                                                                htmlFor="album-cover-guideline"
                                                                                className="md:hidden cursor-pointer rounded-full"
                                                                      >
                                                                                <AiOutlineQuestionCircle size={16} />
                                                                      </label>
                                                            </h3>
                                                  </div>
                                                  <div className='flex flex-col md:flex-row items-center gap-5 pb-3 sm:pb-0 lg:pb-3'>
                                                            <div className='w-full md:w-1/2 lg:w-1/3'>
                                                                      <label
                                                                                htmlFor="choose-album-cover"
                                                                                className={`input-group flex items-center overflow-hidden border-2 ${!albumCoverView && 'py-24'} border-dotted rounded-md mt-2 justify-center cursor-grabbing`}>
                                                                                {albumCoverView ? (
                                                                                          <div
                                                                                                    className="w-full h-60 md:h-72 overflow-hidden mx-auto rounded-md"
                                                                                          >
                                                                                                    <img
                                                                                                              src={albumCoverView}
                                                                                                              alt="previewImage"
                                                                                                              className="w-full h-full object-cover rounded-md"
                                                                                                    />
                                                                                                    {isImageError && (
                                                                                                              <div className="absolute inset-0 flex items-center justify-center bg-error bg-opacity-70 rounded-md">
                                                                                                                        <p className="font-bold text-3xl text-center px-3 md:px-0">Image size must be 3000x3000</p>
                                                                                                              </div>
                                                                                                    )}
                                                                                          </div>
                                                                                ) : (
                                                                                          <div className="icon text-5xl">
                                                                                                    <BsImage />
                                                                                          </div>
                                                                                )}
                                                                      </label>
                                                                      <input
                                                                                type="file"
                                                                                accept='.jpg'
                                                                                className="form-control outline-none pl-4 w-full hidden"
                                                                                id="choose-album-cover"
                                                                                {...register("albumCover")}
                                                                      />
                                                            </div>
                                                            <div className='w-full md:w-1/2 lg:w-2/3 md:flex flex-col hidden'>
                                                                      <p className='flex items-start gap-3'>
                                                                                <span className='mt-1 text-warning'><FaRegLightbulb /></span>Recommended images are 3000x3000 square JPG format. This is just a recommendation though—we accept most image sizes.
                                                                      </p>
                                                                      <p className='flex items-start gap-3'>
                                                                                <span className='mt-1 text-warning'><FaRegLightbulb /></span>Stores will reject artwork that contains a website address (URL), Twitter name, or any image that's pixelated, rotated, or poor quality. They'll also reject artwork with prices or store logos (don't put an iTunes or Spotify logo on your artwork). Also, please don't reuse the same artwork for multiple albums.
                                                                      </p>
                                                                      <p className='flex items-start gap-3'>
                                                                                <span className='mt-1 text-warning'><FaRegLightbulb /></span>You own this artwork and everything in it. Stores will reject your artwork if it contains images you found online that you don't have the explicit right to use.
                                                                      </p>
                                                            </div>
                                                  </div>
                                                  {song?.albumCover && (
                                                            <div className='w-full md:w-1/2 lg:w-1/3'>
                                                                      <img
                                                                                src={song?.albumCover}
                                                                                alt="album-cover"
                                                                                className="w-full h-60 md:h-72 object-cover rounded-md"
                                                                      />
                                                            </div>
                                                  )}
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1 w-full md:w-1/2 lg:w-1/3">
                                                  <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold flex items-center gap-2">Upload your audio file (Only .mp3) <span className='text-red-600'>*</span>
                                                                      <div className="dropdown dropdown-top dropdown-end md:dropdown-hover md:dropdown-right">
                                                                                <label tabIndex={0} className="cursor-pointer">
                                                                                          <AiOutlineQuestionCircle size={16} />
                                                                                </label>
                                                                                <ul tabIndex={0} className="dropdown-content z-[1] menu px-3 py-6 shadow bg-base-100 border-2 rounded-box w-60">
                                                                                          <div className='w-full'>
                                                                                                    <p className='flex items-start gap-3'>
                                                                                                              <span className='mt-1 text-warning'><FaRegLightbulb /></span>Track title and release title must be same.
                                                                                                    </p>
                                                                                          </div>
                                                                                </ul>
                                                                      </div>
                                                            </h3>
                                                  </div>
                                                  <label
                                                            htmlFor={`choose-audio-file`}
                                                            className={`input-group flex items-center my-2 border-2 border-dotted ${isAudioFileView ? 'py-20' : 'py-24'} rounded-md mt-2 justify-center cursor-grabbing`}>
                                                            {isAudioFileView ? (
                                                                      <div
                                                                                className="w-full mx-auto border-success"
                                                                      >
                                                                                <h2 className='flex flex-col gap-3 justify-center items-center text-center h-full'>
                                                                                          <div className='flex items-center gap-2'>
                                                                                                    <BsMusicNote />
                                                                                                    {isAudioFileView?.length > 25 ? (
                                                                                                              <p className='text-gray-500'>{isAudioFileView?.slice(0, 25)}...</p>
                                                                                                    ) : (
                                                                                                              <p className='text-gray-500'>{isAudioFileView}</p>
                                                                                                    )}
                                                                                          </div>
                                                                                          <span className='badge badge-success text-white py-4 uppercase'>Audio uploaded..!</span>
                                                                                </h2>
                                                                      </div>
                                                            ) : (
                                                                      <div className="icon text-5xl">
                                                                                <BsMusicNote />
                                                                      </div>
                                                            )}
                                                  </label>
                                                  <input
                                                            type="file"
                                                            accept=".mp3"
                                                            className="form-control outline-none pl-4 w-full hidden"
                                                            id={`choose-audio-file`}
                                                            {...register(`audioFile`)}
                                                  />
                                                  {song?.audioFile && (
                                                            <audio
                                                                      src={song?.audioFile}
                                                                      controls
                                                                      className="w-full h-10 mt-2"
                                                            />
                                                  )}
                                        </div>
                                        {!song && (
                                                  <div className='mt-10 ml-5'>
                                                            <div className='flex items-center md:items-start gap-2 mb-5'>
                                                                      <input type="checkbox" className="checkbox checkbox-sm"
                                                                                {...register("termsOne")}
                                                                      />
                                                                      <p className='text-sm text-gray-500'>I own, recorded and am otherwise authorized to sell this music in stores worldwide and keep all my royalties.</p>
                                                            </div>
                                                            <div className='flex items-center md:items-start gap-2 mb-5'>
                                                                      <input type="checkbox" className="checkbox checkbox-sm"
                                                                                {...register("termsTwo")}
                                                                      />
                                                                      <p className='text-sm text-gray-500'>I have approval from all artists mentioned in my album art, album title and song titles.</p>
                                                            </div>
                                                            <div className='flex items-center md:items-start gap-2 mb-5'>
                                                                      <input type="checkbox" className="checkbox checkbox-sm"
                                                                                {...register("termsThree")}
                                                                      />
                                                                      <p className='text-sm text-gray-500'>If my work is a remix or contains uncleared samples, I have explicit permission from the original artist.</p>
                                                            </div>
                                                            <div className='flex items-center md:items-start gap-2 mb-5'>
                                                                      <input type="checkbox" className="checkbox checkbox-sm"
                                                                                {...register("termsFour")}
                                                                      />
                                                                      <p className='text-sm text-gray-500'>I have read and agree to the terms of <Link to="/agreement" className='text-indigo-500'>Music's Distribution Agreement</Link> and Terms of Service.</p>
                                                            </div>
                                                  </div>
                                        )}

                                        <div className='flex justify-center items-center mt-16 mb-10'>
                                                  {isLoading || uploadLoading || updatedLoading ? (
                                                            <button className="btn btn-sm md:btn-md font-semibold text-white btn-error" disabled>
                                                                      <PulseLoader color="#fff" size={6} />
                                                            </button>
                                                  ) : (
                                                            <button type='submit' className="btn btn-sm md:btn-md font-semibold text-white bg-gradient-to-l from-gradientSecondary to-gradientPrimary">
                                                                      {song ? 'Update' : 'Submit'}
                                                            </button>
                                                  )}
                                        </div>
                              </form>
                              <ScrollButton />
                              <RemixVersionGuideline />
                              <AlbumCoverGuideline />
                    </div>
          )
}
