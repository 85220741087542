import { ReactNode, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { HiArrowLongLeft } from "react-icons/hi2";
import { IoTextOutline } from "react-icons/io5";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import MiniTitle from "../../../../components/MiniTitle";
import useAuth from "../../../../hooks/useAuth";
import useTitle from "../../../../hooks/useTitle";
import { authUserInterface } from "../../../../interfaces/UserInterface";
import { useAddClaimRequestMutation } from "../../../../redux/features/ClaimRequestApi";

const Fade = require("react-reveal/Fade");

export default function NewClaimRelease() {
          useTitle("New Claim Release");

          const {
                    updatedUser: user,
          } = useAuth<authUserInterface | any>({});

          const { register, handleSubmit, reset, formState: { errors } } = useForm();

          const [newClaimRelease, { data, isLoading, error, isSuccess }] = useAddClaimRequestMutation();

          const onSubmit = async (data: any) => {
                    const { ytUrl, upcCode } = data;

                    const newData = {
                              user: user?._id,
                              ytUrl,
                              upcCode,
                    }

                    Swal.fire({
                              title: 'Are you sure?',
                              text: "You want to request for a new claim release?",
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, Request!'
                    }).then(async (result) => {
                              if (result.isConfirmed) {
                                        await newClaimRelease(newData);
                              }
                    })
          }

          useEffect(() => {
                    if (isSuccess) {
                              Swal.fire({
                                        title: 'Success!',
                                        icon: 'success',
                                        titleText: 'Success',
                                        text: `Hey, ${user?.firstName}! Your request has been submitted successfully.`,
                                        showConfirmButton: false,
                                        timer: 2000,
                              })
                              reset();
                    }
                    if (error) {
                              toast.error((error as any)?.data?.message);
                    }
          }, [data, isSuccess, error, reset, user?.firstName]);

          return (
                    <Fade top distance="20px">
                              <div className="py-0 md:pb-5 lg:py-5 rounded-md">
                                        <MiniTitle title="New Claim Release" />
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                  <div className="name border rounded p-3 relative mt-10 flex-1">
                                                            <div className="name-title absolute -top-5 bg-white border rounded p-1">
                                                                      <h3 className="text-sm font-bold flex items-center gap-2">YT Video Link {errors.ytUrl ? <span className='text-red-500 text-xs flex items-center gap-1'> <HiArrowLongLeft /> {errors.ytUrl?.message as ReactNode}</span> : <span className='text-red-500'>*</span>}</h3>
                                                            </div>
                                                            <div className="flex items-center gap-3">
                                                                      <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                                <div className="icon">
                                                                                          <IoTextOutline />
                                                                                </div>
                                                                                <input
                                                                                          type="url"
                                                                                          className={`form-control outline-none pl-4 w-full text-sm`}
                                                                                          placeholder="Enter YT Video Link"
                                                                                          {...register("ytUrl", {
                                                                                                    required: {
                                                                                                              value: true,
                                                                                                              message: 'Please fill up the YouTube Video Link'
                                                                                                    }
                                                                                          })}
                                                                                />
                                                                      </div>
                                                            </div>
                                                  </div>
                                                  <div className="name border rounded p-3 relative mt-10 flex-1">
                                                            <div className="name-title absolute -top-5 bg-white border rounded p-1">
                                                                      <h3 className="text-sm font-bold flex items-center gap-2">UPC Code {errors.upcCode ? <span className='text-red-500 text-xs flex items-center gap-1'> <HiArrowLongLeft /> {errors.upcCode?.message as ReactNode}</span> : <span className='text-red-500'>*</span>}</h3>
                                                            </div>
                                                            <div className="flex items-center gap-3">
                                                                      <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                                <div className="icon">
                                                                                          <IoTextOutline />
                                                                                </div>
                                                                                <input
                                                                                          type="text"
                                                                                          className={`form-control outline-none pl-4 w-full text-sm`}
                                                                                          placeholder="Enter UPC Code"
                                                                                          {...register("upcCode", {
                                                                                                    required: {
                                                                                                              value: true,
                                                                                                              message: 'Please fill up the UPC Code'
                                                                                                    }
                                                                                          })}
                                                                                />
                                                                      </div>
                                                            </div>
                                                  </div>

                                                  <div className='mt-5 flex justify-end items-center'>
                                                            {isLoading ? (
                                                                      <button className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary" type="button">
                                                                                <PulseLoader size={8} color="#fff" />
                                                                      </button>
                                                            ) : (
                                                                      <button className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white" type="submit">
                                                                                Submit
                                                                      </button>
                                                            )}
                                                  </div>
                                        </form>
                              </div>
                    </Fade>
          )
}