import { useEffect, useState } from "react";

const useTitle = (props: string) => {
          const [title, setTitle] = useState("");

          useEffect(() => {
                    document.title = title + " | Mirror-LTD Music";
                    setTitle(props);
          }, [title, props]);

          return [setTitle, props];
};

export default useTitle;