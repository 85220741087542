import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { MdVerified } from 'react-icons/md';
import Swal from 'sweetalert2';
import useAuth from '../../../../hooks/useAuth';
import { authUserInterface } from '../../../../interfaces/UserInterface';
import { useChangeUserRoleMutation } from '../../../../redux/features/auth/AuthApi';

type Props = {
          admin: any;
          index: number;
};

export default function AdminsCard({ admin }: Props) {
          const { updatedUser } = useAuth<authUserInterface | any>({});
          const [changeUserRole, { data, isLoading, isSuccess, isError, error }] = useChangeUserRoleMutation();

          const fullName = `${admin?.firstName} ${admin?.lastName}`;

          const handleChange = async (e: any, id: string) => {
                    const role = e.target.value.toLowerCase();
                    Swal.fire({
                              title: 'Are you sure?',
                              text: `You want to change ${admin?.firstName} ${admin?.lastName} role to ${role}?`,
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, do it!',
                              cancelButtonText: 'No, cancel!',
                    })
                              .then(async (result) => {
                                        if (result.isConfirmed) {
                                                  await changeUserRole({ id, role });
                                        }
                              })
          };

          useEffect(() => {
                    if (isSuccess) {
                              Swal.fire({
                                        title: 'Success',
                                        text: 'User status changed successfully!',
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1500,
                              });
                    }

                    if (error) {
                              toast.error((error as any)?.data?.message || "something went wrong");
                    }

          }, [data, isLoading, isSuccess, isError, error]);

          return (
                    <div className='border rounded-2xl shadow-md'>
                              <div className={`relative w-full rounded-tl-2xl rounded-tr-2xl shadow-md flex flex-col group`}>
                                        <div className="w-full overflow-hidden rounded-tl-2xl rounded-tr-2xl glass">
                                                  <img
                                                            className="w-full h-28 md:h-40 lg:h-52 object-cover md:group-hover:scale-110 duration-300"
                                                            src={admin?.profileImage}
                                                            alt={admin?.firstName}
                                                            loading='lazy'
                                                  />
                                                  <div className="absolute top-0 md:top-2 right-1 md:right-2">
                                                            <span className={`badge badge-sm md:badge-md text-white ${admin?.isOnline ? 'badge-success' : 'badge-neutral'} opacity-100 transition-opacity duration-300 ease-in-out`}>
                                                                      {admin?.isOnline ? 'Online' : 'Offline'}
                                                            </span>
                                                  </div>
                                        </div>
                                        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#ffffff00] to-black opacity-100 transition-opacity duration-300 ease-in-out rounded-tl-2xl rounded-tr-2xl"></div>
                                        <div className="absolute bottom-0 left-0 p-4 text-white opacity-100 transition-opacity duration-300 ease-in-out rounded-tl-2xl rounded-tr-2xl">
                                                  <h3 className="text-base md:text-lg font-bold hidden md:flex items-center gap-2">
                                                            {fullName} <span title="Verified account"><MdVerified color="#1C96E8" /></span>
                                                  </h3>
                                                  <h3 className="text-base md:text-lg font-bold md:hidden flex items-center gap-2">
                                                            {fullName?.length > 10 ? fullName?.slice(0, 10) + '...' : fullName} <span title="Verified account"><MdVerified color="#1C96E8" /></span>
                                                  </h3>
                                                  <p className='text-xs md:text-sm font-semibold hidden md:block'>{admin?.email}</p>
                                                  <p className='text-xs md:text-sm font-semibold md:hidden'>
                                                            {admin?.email?.length > 15 ? admin?.email?.slice(0, 15) + '...' : admin?.email}
                                                  </p>
                                                  <p className='text-xs md:text-sm font-semibold'>{admin?.phoneNumber}</p>
                                        </div>
                              </div>
                              <div className='flex flex-col justify-center items-center gap-2 my-3'>
                                        <select
                                                  className={`select select-bordered select-xs`}
                                                  defaultValue={
                                                            admin.role
                                                                      ? "Admin"
                                                                      : "User"
                                                  }
                                                  onChange={(e) => {
                                                            handleChange(e, admin._id);
                                                  }}
                                                  disabled={admin?._id === updatedUser?._id}
                                        >
                                                  <option>Admin</option>
                                                  <option>User</option>
                                        </select>
                              </div>
                    </div>
          )
}
