import { Outlet, useNavigate } from "react-router-dom";

export default function ClaimRelease() {
          const navigate = useNavigate();

          let tabs = [
                    {
                              id: 1,
                              title: 'All Claim Releases',
                              link: 'all',
                    },
                    {
                              id: 2,
                              title: 'New Claim Release',
                              link: 'new',
                    }
          ];

          return (
                    <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
                              <div className="title my-2 mb-5">
                                        <h3 className="text-2xl font-bold flex items-center gap-2"> Manage All Claim Releases</h3>
                                        <span>
                                                  You can manage all claim releases here
                                        </span>
                              </div>
                              <div className="tabs tabs-boxed mt-5 gap-2 bg-transparent duration-300">
                                        {tabs.map((tab, i) => (
                                                  <span key={i} className={`tab md:tab-lg border 
                                                  ${tab.link === window.location.pathname.split('/')[2] ? 'tab-active' : ''}
                                                  ${window.location.pathname === '/claim-release' && tab.title === 'All Claim Releases' ? 'tab-active' : ''}
                                                  `}
                                                            onClick={() => navigate(`/claim-release/${tab.link}`)
                                                            }>
                                                            {tab.title}
                                                  </span>
                                        ))}
                              </div>
                              <div>
                                        <Outlet />
                              </div>
                    </div>
          )
}