import React from 'react';
import useTitle from '../../../../hooks/useTitle';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import { Outlet, useNavigate } from 'react-router-dom';

export default function Applications() {
          useTitle("Applications");
          useScrollToTop();

          const navigate = useNavigate();

          let tabs = [
                    {
                              id: 1,
                              title: 'Pending',
                              link: 'pending-applications'
                    },
                    {
                              id: 2,
                              title: 'Approved',
                              link: 'approved-applications'
                    },
                    {
                              id: 3,
                              title: 'Rejected',
                              link: 'rejected-applications'
                    }
          ];

          return (
                    <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
                              <div className="title my-2 mb-5">
                                        <h3 className="text-2xl font-bold">Manage Applications</h3>
                                        <span>You can manage all the applications whom are already applied</span>
                              </div>
                              <div className="tabs tabs-boxed mt-5 gap-2 bg-transparent duration-300">
                                        {tabs.map((tab, i) => (
                                                  <span key={i} className={`tab md:tab-lg border 
                                                  ${tab.link === window.location.pathname.split('/')[2] ? 'tab-active' : ''}
                                                  ${window.location.pathname === '/applications' && tab.title === 'Pending' ? 'tab-active' : ''}
                                                  `}
                                                            onClick={() => navigate(`/applications/${tab.link}`)}
                                                  >
                                                            {tab.title}
                                                  </span>
                                        ))}
                              </div>
                              <div>
                                        <Outlet />
                              </div>
                    </div>
          );
};