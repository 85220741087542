import React from 'react'
import useTitle from '../../../../hooks/useTitle'
import { Outlet, useNavigate } from 'react-router-dom';

export default function ManageAllReleases() {
          useTitle('Manage All Releases');

          const navigate = useNavigate();

          let tabs = [
                    {
                              id: 1,
                              title: 'Pending',
                              link: 'pending-all-releases'
                    },
                    {
                              id: 2,
                              title: 'Approved',
                              link: 'approved-all-releases'
                    },
                    {
                              id: 3,
                              title: 'Rejected',
                              link: 'rejected-all-releases'
                    },
                    {
                              id: 4,
                              title: 'Take Down',
                              link: 'takedown-all-releases'
                    }
          ];

          return (
                    <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
                              <div className="title my-2 mb-5">
                                        <h3 className="text-2xl font-bold flex items-center gap-2"> Manage All Releases</h3>
                                        <span>
                                                  You can manage all releases here
                                        </span>
                              </div>
                              <div className="tabs tabs-boxed mt-5 gap-2 bg-transparent duration-300">
                                        {tabs.map((tab, i) => (
                                                  <span key={i} className={`tab md:tab-lg border 
                                                  ${tab.link === window.location.pathname.split('/')[2] ? 'tab-active' : ''}
                                                  ${window.location.pathname === '/all-releases' && tab.title === 'Pending' ? 'tab-active' : ''}
                                                  `}
                                                            onClick={() => navigate(`/all-releases/${tab.link}`)}
                                                  >
                                                            {tab.title}
                                                  </span>
                                        ))}
                              </div>

                              <div>
                                        <Outlet />
                              </div>
                    </div>
          )
}
