import { baseApi } from "../baseApi";

export const waitingUserApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWaitingUser: builder.query({
      query: () => `/waiting-users`,
      providesTags: ["waitingUser"],
    }),
    getAllWaitingUserByStatus: builder.query({
      query: ({ status }) =>
        `/waiting-users/find/get-by-status?status=${status}`,
      providesTags: ["waitingUser"],
    }),
    getWaitingUserByEmail: builder.query<any, string>({
      query: (email: string) => `/waiting-users/${email}`,
      providesTags: ["waitingUser"],
    }),
    getWaitingUserById: builder.query<any, string>({
      query: (id: string) => `/waiting-users/user/${id}`,
      providesTags: ["waitingUser"],
    }),
    getWaitingUserEmails: builder.query<any, any>({
      query: () => `/waiting-users/find/emails`,
      providesTags: ["waitingUser"],
    }),
    rejectedWaitingUser: builder.mutation({
      query: (body) => ({
        url: "/waiting-users/reject",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["waitingUser"],
    }),
  }),
});

export const {
  useGetWaitingUserQuery,
  useGetAllWaitingUserByStatusQuery,
  useGetWaitingUserByEmailQuery,
  useGetWaitingUserByIdQuery,
  useGetWaitingUserEmailsQuery,
  useRejectedWaitingUserMutation,
} = waitingUserApi;
