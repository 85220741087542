import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from "react-query";
import store from './redux/store';
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Toaster />
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

console.log(
  '%cStop! This is a browser feature intended for developers. If someone told you to copy and paste something here to enable a feature or "hack" someone\'s account, it is a scam and will give them access to your account. If you are a developer, you can use this console to debug your code. If you are not a developer, close this window and go about your day.',
  "color:red;font-family:system-ui;font-size:2.5rem;-webkit-text-stroke: 1px black;font-weight:bold"
);