import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AiOutlineEdit } from 'react-icons/ai';
import { BsFacebook, BsYoutube } from 'react-icons/bs';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import GlobalLoader from '../../../../components/GlobalLoader';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import useTitle from '../../../../hooks/useTitle';
import { useAddBalanceMutation, useChangeUserRoleMutation, useChangeUserStatusMutation, useGetUserByIdQuery, useUpdatePercentageMutation } from '../../../../redux/features/auth/AuthApi';
import { useGetWalletByUserIdQuery } from '../../../../redux/features/WalletApi';
import BalanceAddModal from './BalanceAddModal';
import PercentageUpdateModal from './PercentageUpdateModal';
import WalletHistory from './WalletHistory';

type Props = {
          refetch: () => void;
};

export default function UserDetails({ refetch }: Props) {
          useScrollToTop();

          const { id } = useParams();

          const navigate = useNavigate();

          const { data, isLoading } = useGetUserByIdQuery(id);

          const { data: transactions, isLoading: walletLoading } = useGetWalletByUserIdQuery({ userId: id })

          const [addBalance, { data: balanceData, isLoading: balanceLoading, isSuccess: balanceSuccess, error: balanceError }] = useAddBalanceMutation();

          const [updatePercentage, { data: percentageData, isLoading: percentageLoading, isSuccess: percentageSuccess, error: percentageError }] = useUpdatePercentageMutation();

          const { register, handleSubmit, reset, setValue } = useForm();

          const handleAddBalance = handleSubmit(async (data) => {
                    const formData = {
                              id,
                              balance: Number(data.balance)
                    }
                    await addBalance(formData);
          })

          const handleUpdatePercentage = handleSubmit(async (data) => {
                    const percentage = Number(data.profitPercentage);

                    if (percentage < 0 || percentage > 100) return toast.error('Percentage must be between 0 to 100');

                    const formData = {
                              id,
                              profitPercentage: percentage
                    }
                    await updatePercentage(formData);
          })

          const user = data?.data;

          const [changeStatus, { data: statusData, isLoading: statusLoading, isSuccess: statusSuccess, error: statusError }] = useChangeUserStatusMutation();

          const [changeUserRole, { data: userRoleData, isLoading: roleIsLoading, isSuccess: roleIsSuccess, error: roleError }] = useChangeUserRoleMutation();

          const handleStatusChange = async (e: any, id: string) => {
                    const status = e.target.value === "Unblocked" ? true : false;
                    Swal.fire({
                              title: 'Are you sure?',
                              text: `You want to ${status ? 'unblock' : 'block'} this user`,
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: `Yes, ${status ? 'unblock' : 'block'} it!`,
                              cancelButtonText: 'No, cancel!',
                    })
                              .then(async (result) => {
                                        if (result.isConfirmed) {
                                                  await changeStatus({ id, status });
                                        }
                              })
          };

          const handleChangeUserRole = async (e: any, id: string) => {
                    const role = e.target.value.toLowerCase();
                    Swal.fire({
                              title: 'Are you sure?',
                              text: `You want to change ${user?.firstName} ${user?.lastName} role to ${role}?`,
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, do it!',
                              cancelButtonText: 'No, cancel!',
                    })
                              .then(async (result) => {
                                        if (result.isConfirmed) {
                                                  await changeUserRole({ id, role });
                                        }
                              })
          };

          useTitle(user?.firstName + ' ' + user?.lastName);


          useEffect(() => {
                    setValue("balance", user?.balance);
                    setValue("profitPercentage", user?.profitPercentage);

                    if (statusSuccess) {
                              Swal.fire({
                                        title: 'Success',
                                        text: `User ${user?.status ? 'unblocked' : 'blocked'} successfully!`,
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1500,
                              });
                              refetch();
                    }

                    if (balanceSuccess) {
                              toast.success(balanceData?.message);
                              reset();
                    }

                    if (percentageSuccess) {
                              toast.success(percentageData?.message);
                              reset();
                    }

                    if (roleIsSuccess) {
                              toast.success(userRoleData?.message);
                              navigate("/all-artists");
                    }

                    if (balanceError) {
                              toast.error((balanceError as any)?.balanceData?.message || balanceData?.message);
                    }

                    if (percentageError) {
                              toast.error((percentageError as any)?.percentageData?.message || percentageData?.message);
                    }

                    if (statusError) {
                              toast.error((statusError as any)?.data?.message || statusData?.message);
                    }

                    if (roleError) {
                              toast.error((roleError as any)?.data?.message || userRoleData?.message);
                    }

          }, [balanceData?.message, percentageData?.message, statusData?.message, userRoleData?.message, navigate, balanceSuccess, reset, setValue, statusSuccess, user?.balance, user?.status, user?.profitPercentage, roleIsSuccess, roleError, refetch, statusError, balanceError, percentageError, percentageSuccess]);

          let tabs = ['Basic', 'Songs', 'Wallet', 'Action'];
          const [activeTab, setActiveTab] = useState<number>(0);


          if (isLoading || statusLoading || roleIsLoading || walletLoading) {
                    return <GlobalLoader />
          }

          return (
                    <div className="md:pb-5 pb-8 rounded-md">
                              <div className="card">
                                        <div className='my-4'>
                                                  <button className='flex items-center gap-2 cursor-pointer' onClick={() => navigate(-1)}><HiOutlineArrowNarrowLeft size={25} /><span className='text-base md:text-xl lg:text-2xl font-bold'>{user?.firstName} {user?.lastName}</span></button>
                                        </div>
                                        <div className='mt-2 bg-globalColor rounded-xl h-full p-4 flex flex-col justify-between md:items-start relative'>
                                                  <p className={`hidden md:flex absolute top-4 right-4 py-1 px-3 ${user?.isOnline ? 'bg-gradient-to-l from-gradientSecondary to-gradientPrimary' : 'bg-neutral'} text-white rounded-lg capitalize`}>{user?.isOnline ? 'Online' : 'Offline'}</p>
                                                  <div className='flex justify-between md:justify-center md:items-center gap-3 md:gap-5 overflow-hidden'>
                                                            <img src={user?.profileImage} className='w-40 md:w-96 h-40 md:h-72 rounded-xl object-cover' alt={user?.email} />
                                                            <div>
                                                                      <h1 className='text-base md:text-2xl text-white font-bold'>{user?.firstName} {user?.lastName}</h1>
                                                                      <p className='text-white text-sm md:text-lg hidden md:block'>{user?.email}</p>
                                                                      <p className='text-white text-sm md:text-lg md:hidden'>
                                                                                {user?.email?.length > 18 ? user?.email?.slice(0, 18) + '...' : user?.email}
                                                                      </p>
                                                                      <p className='text-white text-sm md:text-lg hidden md:flex'>
                                                                                {user?.artistBio?.length > 100 ? (
                                                                                          <>
                                                                                                    {user?.artistBio?.slice(0, 150)}...
                                                                                          </>
                                                                                ) : (
                                                                                          <>
                                                                                                    {user?.artistBio}
                                                                                          </>
                                                                                )}
                                                                      </p>
                                                                      {
                                                                                user?.fbLink || user?.ytLink ? (
                                                                                          <div className="flex justify-start items-center gap-5 pt-3 pb-3">
                                                                                                    {user?.fbLink && (
                                                                                                              <li className="tooltip" data-tip="Facebook">
                                                                                                                        <a
                                                                                                                                  href={user?.fbLink}
                                                                                                                                  target="_blank"
                                                                                                                                  className="btn-sm text-lg btn-circle group btn-ghost btn btn-outline hover:btn-error text-white"
                                                                                                                                  rel="noreferrer"
                                                                                                                        >
                                                                                                                                  <BsFacebook className='group-hover:text-white duration-300' />
                                                                                                                        </a>
                                                                                                              </li>
                                                                                                    )}
                                                                                                    {user?.ytLink && (
                                                                                                              <li className="tooltip" data-tip="YouTube Channel">
                                                                                                                        <a
                                                                                                                                  href={user?.ytLink}
                                                                                                                                  target="_blank"
                                                                                                                                  className="btn-sm text-lg btn-circle group btn-ghost btn btn-outline hover:btn-error text-white"
                                                                                                                                  rel="noreferrer"
                                                                                                                        >
                                                                                                                                  <BsYoutube className='group-hover:text-white duration-300' />
                                                                                                                        </a>
                                                                                                              </li>
                                                                                                    )}
                                                                                          </div>
                                                                                ) : (
                                                                                          <></>
                                                                                )
                                                                      }
                                                                      <div className='flex justify-between md:justify-start items-center md:items-start gap-5 md:gap-7'>
                                                                                <p className='flex items-center gap-2 mt-2 md:mt-5'>
                                                                                          <span className='font-black text-xl text-white'>$ {user?.balance}</span>
                                                                                          <label
                                                                                                    htmlFor="addBalance"
                                                                                                    className="text-white border-none btn btn-xs btn-circle bg-gradient-to-l from-gradientSecondary to-gradientPrimary duration-300"
                                                                                          >
                                                                                                    <AiOutlineEdit />
                                                                                          </label>
                                                                                </p>
                                                                                <p className='flex items-center gap-2 mt-2 md:mt-5'>
                                                                                          <span className='font-black text-xl text-white'>{user?.profitPercentage} %</span>
                                                                                          <label
                                                                                                    htmlFor="updatePercentage"
                                                                                                    className="text-white border-none btn btn-xs btn-circle bg-gradient-to-l from-gradientSecondary to-gradientPrimary duration-300"
                                                                                          >
                                                                                                    <AiOutlineEdit />
                                                                                          </label>
                                                                                </p>
                                                                      </div>
                                                            </div>
                                                  </div>
                                                  <div className='bg-gray-600 mt-5 rounded-xl md:hidden flex justify-between items-center py-3 px-3'>
                                                            <h1 className='text-white font-bold'>Status</h1>
                                                            <p className={`py-1 px-3 ${user?.isOnline ? 'bg-gradient-to-l from-gradientSecondary to-gradientPrimary' : 'bg-neutral'} text-white rounded-lg capitalize`}>{user?.isOnline ? 'Online' : 'Offline'}</p>
                                                  </div>
                                        </div>
                                        <div className="tabs tabs-boxed mt-5 gap-2 bg-transparent duration-300">
                                                  {tabs.map((tab, i) => (
                                                            <span key={i} className={`tab md:tab-lg border ${activeTab === i ? 'tab-active' : ''}`}
                                                                      onClick={() => setActiveTab(i)}
                                                            >
                                                                      {tab}
                                                            </span>
                                                  ))}
                                        </div>
                                        {
                                                  activeTab === 0 && (
                                                            <div className='mt-5 flex flex-col justify-start gap-3'>
                                                                      <p><span className='font-bold'>Artist Bio: </span>{user?.artistBio}</p>
                                                                      <p><span className='font-bold'>Phone Number: </span>{user?.phoneNumber}</p>
                                                                      <p className='capitalize'><span className='font-bold'>Artist Address: </span>{
                                                                                user?.address ? (
                                                                                          <>
                                                                                                    {user?.address}, {user?.zipCode}, {user?.city}, {user?.country}
                                                                                          </>
                                                                                ) : (
                                                                                          <span>N/A</span>
                                                                                )
                                                                      }</p>
                                                                      <p className='flex items-center gap-3'><span className='font-bold'>User Allow Login: </span>{user?.status ? (
                                                                                <span className="badge bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white p-3">Unblocked</span>
                                                                      ) : (
                                                                                <span className="badge badge-error text-white">Blocked</span>
                                                                      )}</p>
                                                                      <p><span className='font-bold'>Registered at: </span>{moment(user?.createdAt).format('MMM Do YYYY, h:mm:ss a')}</p>
                                                            </div>
                                                  )
                                        }
                                        {
                                                  activeTab === 1 && (
                                                            <div className='mt-5'>
                                                                      <Link to={`/all-artists/${user._id}/songs`} className='btn btn-sm bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white'>View All Songs</Link>
                                                            </div>
                                                  )
                                        }
                                        {
                                                  activeTab === 2 && (
                                                            <WalletHistory userId={user?._id} walletLoading={walletLoading} wallet={transactions?.data} refetch={refetch} />
                                                  )
                                        }
                                        {
                                                  activeTab === 3 && (
                                                            <div className="mt-5">
                                                                      <div className='flex items-center gap-3 mb-3 border p-3 rounded-lg'>
                                                                                <span className='font-bold'>Allow Login:
                                                                                          <select
                                                                                                    className={`ml-2 select select-bordered select-sm`}
                                                                                                    defaultValue={
                                                                                                              user.status
                                                                                                                        ? "Unblocked"
                                                                                                                        : "Blocked"
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                              handleStatusChange(e, user._id);
                                                                                                    }}
                                                                                          >
                                                                                                    <option>Unblocked</option>
                                                                                                    <option>Blocked</option>
                                                                                          </select>
                                                                                </span>
                                                                      </div>
                                                                      <div className='flex items-center gap-3 border p-3 rounded-lg'>
                                                                                <span className='font-bold'>Role:
                                                                                          <select
                                                                                                    className={`ml-2 select select-bordered select-sm`}
                                                                                                    defaultValue={
                                                                                                              user.role
                                                                                                                        ? "User"
                                                                                                                        : "Admin"
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                              handleChangeUserRole(e, user._id);
                                                                                                    }}
                                                                                          >
                                                                                                    <option>User</option>
                                                                                                    <option>Admin</option>
                                                                                          </select>
                                                                                </span>
                                                                      </div>
                                                            </div>
                                                  )
                                        }
                              </div>
                              <BalanceAddModal user={user} isLoading={isLoading} balanceLoading={balanceLoading} handleAddBalance={handleAddBalance} register={register} />
                              <PercentageUpdateModal user={user} isLoading={isLoading} percentageLoading={percentageLoading} handleUpdatePercentage={handleUpdatePercentage} register={register} />
                    </div>
          )
}
