import { baseApi } from "../baseApi";

export const claimRequestApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllClaimRequests: builder.query({
      query: () => ({
        url: "/claim-requests/all",
        method: "GET",
      }),
      providesTags: ["claimRequest"],
    }),
    addClaimRequest: builder.mutation({
      query: (body) => ({
        url: "/claim-requests/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["claimRequest"],
    }),
    getClaimRequestByUser: builder.query({
      query: ({ userId }) => ({
        url: `/claim-requests/user/${userId}`,
        method: "GET",
      }),
      providesTags: ["claimRequest"],
    }),
    deleteClaimRequest: builder.mutation({
      query: ({ id }) => ({
        url: `/claim-requests/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["claimRequest"],
    }),
  }),
});

export const {
  useGetAllClaimRequestsQuery,
  useAddClaimRequestMutation,
  useGetClaimRequestByUserQuery,
  useDeleteClaimRequestMutation,
} = claimRequestApi;
