import { useState } from 'react';
import { FaClock, FaClockRotateLeft } from 'react-icons/fa6';
import GlobalLoader from '../../../../components/GlobalLoader';
import { formateDate } from '../../../../configs/global';
import useAuth from '../../../../hooks/useAuth';
import useTitle from '../../../../hooks/useTitle';
import { authUserInterface } from '../../../../interfaces/UserInterface';
import { useGetWalletByUserIdQuery } from '../../../../redux/features/WalletApi';
import WithdrawModal from './WithdrawModal';

type Props = {
          refetch: () => void;
};

export default function Wallet({ refetch }: Props) {
          useTitle("Wallet");
          const status = 'withdrawal'
          const status2 = 'withdrawal approved'

          const { updatedUser, isLoading } = useAuth<authUserInterface | any>({});

          const { data, isLoading: walletLoading } = useGetWalletByUserIdQuery({ userId: updatedUser?._id as string });

          const wallet = data?.data;

          const [isSeeTransaction, setIsSeeTransaction] = useState<boolean>(false);

          if (isLoading || walletLoading) {
                    return <GlobalLoader />
          }

          return (
                    <>
                              <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
                                        <div className='flex justify-between items-center mb-7'>
                                                  <div className="title">
                                                            <h3 className="text-2xl font-black">$ {updatedUser?.balance}</h3>
                                                            <span>Available Balance</span>
                                                  </div>
                                                  <div className="title">
                                                            <label
                                                                      htmlFor={`${wallet?.balance < 1 ? '' : 'withdraw-modal'}`}
                                                                      className="cursor-pointer my-4 text-2xl grid place-items-end"
                                                            >
                                                                      <div className={`${wallet?.balance < 1 && 'btn-disabled'} btn bg-gradient-to-l from-gradientSecondary to-gradientPrimary rounded-full text-white capitalize`}>Withdraw</div>
                                                            </label>
                                                  </div>
                                        </div>

                                        <div className='mt-10'>
                                                  <div className='bg-gray-200 py-3 md:py-4 rounded-full flex justify-start pl-5 items-center gap-2'>
                                                            <FaClock />
                                                            <h1 className='font-bold text-sm md:text-[16px]'>Pending Request</h1>
                                                  </div>
                                                  {
                                                            wallet?.pending?.length > 0 ? (
                                                                      <div className='mt-8 mx-3 grid grid-cols-1 gap-y-5'>
                                                                                {
                                                                                          wallet?.pending?.map((req: any) => (
                                                                                                    <div className='flex justify-between' key={req?._id}>
                                                                                                              <div className='flex justify-between items-center gap-8 md:gap-5'>
                                                                                                                        <p className='bg-yellow-500 text-yellow-800 px-4 py-3 rounded-md'>W</p>
                                                                                                                        <p className='flex flex-col'>
                                                                                                                                  <span className='font-bold md:hidden capitalize'>{
                                                                                                                                            status.length > 6 ? status.slice(0, 6) + '...' : status
                                                                                                                                  }</span>
                                                                                                                                  <span className='font-bold hidden md:flex capitalize'>{status}</span>
                                                                                                                                  <span className='text-gray-400'>#{req?._id?.slice(-4)}</span>
                                                                                                                        </p>
                                                                                                              </div>
                                                                                                              <div className='flex justify-between items-center gap-8 md:gap-5'>
                                                                                                                        <p className='text-gray-400'>{formateDate(req?.createdAt)}</p>
                                                                                                                        <p className='font-bold text-warning'>${req?.amount}</p>
                                                                                                              </div>
                                                                                                    </div>
                                                                                          ))
                                                                                }
                                                                      </div>
                                                            ) : (
                                                                      <div className='flex justify-center items-center mt-10'>
                                                                                <h1 className='text-lg'>No Pending Request</h1>
                                                                      </div>
                                                            )
                                                  }
                                        </div>

                                        <div className='mt-10'>
                                                  <div className='bg-gray-200 py-3 md:py-4 rounded-full flex justify-start pl-5 items-center gap-2'>
                                                            <FaClockRotateLeft />
                                                            <h1 className='font-bold text-sm md:text-[16px]'>Transaction History</h1>
                                                  </div>
                                                  {
                                                            isSeeTransaction && (
                                                                      <div>
                                                                                {
                                                                                          wallet?.transactions?.length > 0 ? (
                                                                                                    <div className='mt-8 mx-3 grid grid-cols-1 gap-y-5'>
                                                                                                              {
                                                                                                                        wallet?.transactions?.map((trans: any) => (
                                                                                                                                  <div className='flex justify-between' key={trans?._id}>
                                                                                                                                            <div className='flex justify-between items-center gap-8 md:gap-5'>
                                                                                                                                                      <p className='bg-red-300 text-red-600 px-4 py-3 rounded-md'>D</p>
                                                                                                                                                      <p className='flex flex-col'>
                                                                                                                                                                <span className='font-bold md:hidden capitalize'>{
                                                                                                                                                                          status2.length > 6 ? status2.slice(0, 6) + '...' : status2
                                                                                                                                                                }</span>
                                                                                                                                                                <span className='font-bold hidden md:flex capitalize'>{status2}</span>
                                                                                                                                                                <span className='text-gray-400'>#{trans?._id?.slice(-4)}</span>
                                                                                                                                                      </p>
                                                                                                                                            </div>
                                                                                                                                            <div className='flex justify-between items-center gap-8 md:gap-5'>
                                                                                                                                                      <p className='text-gray-400'>{formateDate(trans?.createdAt)}</p>
                                                                                                                                                      <p className='font-bold text-red-500'>$-{trans?.amount}</p>
                                                                                                                                            </div>
                                                                                                                                  </div>
                                                                                                                        ))
                                                                                                              }
                                                                                                    </div>
                                                                                          ) : (
                                                                                                    <div className='flex justify-center items-center mt-10'>
                                                                                                              <h1 className='text-lg'>No Transaction History</h1>
                                                                                                    </div>
                                                                                          )
                                                                                }
                                                                      </div>
                                                            )
                                                  }
                                        </div>

                                        <div className='mt-10 flex justify-center'>
                                                  <button onClick={() => setIsSeeTransaction(!isSeeTransaction)} className='btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white no-animation'>{isSeeTransaction ? 'Hide' : 'See'} Transactions History</button>
                                        </div>

                                        <div className="alert shadow-lg flex flex-col mx-auto mt-10 w-full md:w-[300px] select-none text-center no-animation">
                                                  <span className='flex items-center gap-2'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg><h1 className='font-bold text-lg underline'>Attention</h1></span>
                                                  <span>You will get {updatedUser?.profitPercentage}% from you sales.</span>
                                                  <span>Minimum payout 40 USD.</span>
                                        </div>
                              </div>
                              <WithdrawModal wallet={wallet} id={updatedUser?._id} updatedUser={updatedUser} refetch={refetch} />
                    </>
          )
}
