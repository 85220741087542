import { ClipLoader } from "react-spinners";

const GlobalLoader = () => {
          return (
                    <div className="flex justify-center items-center text-center h-screen">
                              <ClipLoader color={"#504FF9"} />
                    </div>
          );
};

export default GlobalLoader;