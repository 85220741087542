import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { BsSpotify } from 'react-icons/bs';
import { HiArrowLongLeft } from 'react-icons/hi2';
import { SiApplemusic } from 'react-icons/si';
import { TbEdit } from 'react-icons/tb';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import DownloadAudio from '../../../../components/DownloadAudio';
import GlobalLoader from '../../../../components/GlobalLoader';
import { findGenre, findService, handleCopiedToClipboard, handleDownload } from '../../../../configs/global';
import useAuth from '../../../../hooks/useAuth';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import useTitle from '../../../../hooks/useTitle';
import { authUserInterface } from '../../../../interfaces/UserInterface';
import { useApproveSongStatusMutation, useGetSongByIdQuery, useRejectSongStatusMutation, useTakedownSongMutation } from '../../../../redux/features/SongApi';

export default function SingleRelease() {
          useScrollToTop();
          const { id } = useParams<{ id: string }>();

          const navigate = useNavigate();

          const {
                    updatedUser
          } = useAuth<authUserInterface | any>({});

          const role = updatedUser?.role;

          const { register, handleSubmit, reset } = useForm();

          const { data, isLoading } = useGetSongByIdQuery({ id });
          const song = data?.data?.song;
          const songUser = data?.data?.user;

          const [approveSongStatus, { isLoading: approveSongLoading, isSuccess: approveSongSuccess, error: approveSongError }] = useApproveSongStatusMutation();

          const [rejectSongStatus, { isLoading: rejectSongLoading, isSuccess: rejectSongSuccess, error: rejectSongError }] = useRejectSongStatusMutation();

          const [takedownSong, { isLoading: takedownSongLoading, isSuccess: takedownSongIsSuccess, error: takedownSongError }] = useTakedownSongMutation();

          let tabs = ['Basic Info', 'Audio', 'Action'];
          const [activeTab, setActiveTab] = useState<number>(0);

          const [isShowTakedownForm, setIsShowTakedownForm] = useState<boolean>(false);
          const [isShowApproveForm, setIsShowApproveForm] = useState<boolean>(false);

          useTitle(`${song?.releaseTitle}`)

          const handleApprovedSongStatus = handleSubmit(async (formData) => {
                    const upcCode = formData.upcCode;
                    const isrcCode = formData.isrcCode;

                    if (!upcCode) {
                              return toast.error('UPC Code is required!');
                    }

                    if (!isrcCode) {
                              return toast.error('ISRC Code is required!');
                    }

                    const songId = song?._id;
                    const userId = song?.owner;

                    Swal.fire({
                              title: 'Are you sure?',
                              text: `You want to approved this song!`,
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: `Yes, approved it!`
                    }).then(async (result: any) => {
                              if (result.isConfirmed) {
                                        await approveSongStatus({ songId, userId, isrcCode, upcCode });
                              }
                    })
          });

          const handleRejectSongStatus = handleSubmit(() => {
                    const songId = song?._id;
                    const userId = song?.owner;

                    Swal.fire({
                              title: 'Are you sure?',
                              text: `You want to reject this song!`,
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: `Yes, reject it!`
                    }).then(async (result: any) => {
                              if (result.isConfirmed) {
                                        await rejectSongStatus({ songId, userId });
                              }
                    })
          });

          const handleTakeDownSong = handleSubmit(async (formData) => {
                    const claimantEmail = formData.claimantEmail;

                    await takedownSong({ songId: id, userId: song?.owner, claimantEmail: claimantEmail });
          });

          useEffect(() => {
                    if (approveSongSuccess) {
                              Swal.fire({
                                        position: 'center',
                                        icon: 'success',
                                        title: 'Song has been approved!',
                                        showConfirmButton: false,
                                        timer: 1500
                              })
                              reset();
                              setIsShowApproveForm(false);
                              navigate(-1);
                    }

                    if (rejectSongSuccess) {
                              Swal.fire({
                                        position: 'center',
                                        icon: 'success',
                                        title: 'Song has been rejected!',
                                        showConfirmButton: false,
                                        timer: 1500
                              })
                              navigate(-1);
                    }

                    if (takedownSongIsSuccess) {
                              Swal.fire({
                                        position: 'center',
                                        icon: 'success',
                                        title: 'Song has been takedown!',
                                        text: `${song?.releaseTitle} has been takedown!`,
                                        showConfirmButton: false,
                                        timer: 1500
                              })
                              navigate(-1);
                    }

                    if (approveSongError) {
                              Swal.fire({
                                        position: 'center',
                                        icon: 'error',
                                        title: 'Something went wrong!',
                                        showConfirmButton: false,
                                        timer: 1500
                              })
                    }

                    if (rejectSongError) {
                              Swal.fire({
                                        position: 'center',
                                        icon: 'error',
                                        title: 'Something went wrong!',
                                        showConfirmButton: false,
                                        timer: 1500
                              })
                    }

                    if (takedownSongError) {
                              Swal.fire({
                                        position: 'center',
                                        icon: 'error',
                                        title: 'Something went wrong!',
                                        showConfirmButton: false,
                                        timer: 1500
                              })
                    }
          }, [approveSongSuccess, rejectSongSuccess, rejectSongError, approveSongError, takedownSongIsSuccess, takedownSongError, song?.releaseTitle, reset, navigate])

          if (isLoading || approveSongLoading) {
                    return <GlobalLoader />
          }

          return (
                    <div className="md:pb-5 pb-8 rounded-md">
                              <div className="card">
                                        <div className='my-4'>
                                                  <button className='flex items-center gap-2 cursor-pointer' onClick={() => navigate(-1)}><HiArrowLongLeft size={25} /><span className='text-base md:text-xl lg:text-2xl font-bold'>{song?.releaseTitle?.slice(0, 30)}</span></button>
                                        </div>
                                        <div className='mt-2 bg-globalColor rounded-xl h-full p-4 flex flex-col justify-between md:items-start relative'>
                                                  <p className={`hidden md:flex absolute top-4 right-4 py-1 px-3 ${song?.status === 'pending' ? 'bg-warning' : song?.status === 'approved' ? 'bg-gradient-to-l from-gradientSecondary to-gradientPrimary' : 'bg-error'} text-white rounded-lg capitalize`}>{song?.status}</p>
                                                  <div className='flex justify-between md:justify-center md:items-center gap-2 md:gap-5'>
                                                            <img src={song?.albumCover} className='w-40 md:w-96 h-full md:h-72 rounded-xl object-cover' alt={song?.releaseTitle} />
                                                            <div>
                                                                      <h1 className='text-base md:text-2xl text-white font-bold'>{song?.releaseTitle}</h1>
                                                                      <p className='text-white text-sm md:text-lg'>
                                                                                {
                                                                                          song?.displayArtist?.map((artist: any) => (
                                                                                                    <span key={artist?._id} className='capitalize'>{artist?.artistName}{song?.displayArtist?.length > 1 && song?.displayArtist?.indexOf(artist) !== song?.displayArtist?.length - 1 ? ', ' : ''}</span>

                                                                                          ))
                                                                                }
                                                                      </p>
                                                            </div>
                                                  </div>
                                                  <div className='bg-gray-600 mt-5 rounded-xl md:hidden flex justify-between items-center py-3 px-3'>
                                                            <h1 className='text-white font-bold'>Status</h1>
                                                            <p className={`py-1 px-3 ${song?.status === 'pending' ? 'bg-warning' : song?.status === 'approved' ? 'bg-gradient-to-l from-gradientSecondary to-gradientPrimary' : 'bg-error'} text-white rounded-lg capitalize`}>{song?.status}</p>
                                                  </div>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                                  <div className="tabs tabs-boxed mt-5 gap-2 bg-transparent duration-300">
                                                            {tabs.map((tab, i) => (
                                                                      <span key={i} className={`tab md:tab-lg border 
                                                            ${activeTab === i ? 'tab-active' : ''}
                                                            ${role === 'user' && tab === 'Action' ? 'hidden' : ''}
                                                            ${song?.status === 'takedown' && tab === 'Action' ? 'hidden' : ''}
                                                            ${song?.status === 'rejected' && tab === 'Action' ? 'hidden' : ''}
                                                            `}
                                                                                onClick={() => setActiveTab(i)}
                                                                      >
                                                                                {tab}
                                                                      </span>
                                                            ))}
                                                  </div>
                                                  {role === 'admin' && (
                                                            <Link to={`/approved-all-releases/${id}/edit`} className='py-2 px-3 bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white rounded-lg capitalize'>
                                                                      <TbEdit />
                                                            </Link>
                                                  )}
                                        </div>
                                        {
                                                  activeTab === 0 && (
                                                            <div className='mt-5'>
                                                                      <div className={`${role === 'admin' && 'grid grid-cols-1 md:grid-cols-2 gap-5'}`}>
                                                                                <div className='alert flex flex-col gap-1.5 items-start justify-start shadow-lg border rounded-xl'>
                                                                                          <div className='text-start'>
                                                                                                    <h1 className='font-bold text-xl md:text-2xl'>{song?.releaseTitle}</h1>
                                                                                                    <span className='text-base md:text-lg font-bold'>{
                                                                                                              song?.displayArtist?.map((artist: any) => (
                                                                                                                        <span key={artist?._id} className='capitalize'>{artist?.artistName}{song?.displayArtist?.length > 1 && song?.displayArtist?.indexOf(artist) !== song?.displayArtist?.length - 1 ? ', ' : ''}</span>

                                                                                                              ))
                                                                                                    }</span>
                                                                                          </div>
                                                                                          {
                                                                                                    song?.status === 'approved' && song?.upcCode && (
                                                                                                              <p className='flex items-center gap-2 mt-5'>
                                                                                                                        <span className='font-bold'>UPC Code: </span><span className='badge bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white font-bold p-3 md:cursor-pointer' onClick={() => handleCopiedToClipboard(song?.upcCode, 'UPC Code')}>{song?.upcCode}</span>
                                                                                                              </p>
                                                                                                    )
                                                                                          }
                                                                                          {
                                                                                                    song?.status === 'approved' && song?.isrcCode && (
                                                                                                              <p className='flex items-center gap-2'>
                                                                                                                        <span className='font-bold'>ISRC Code: </span><span className='badge bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white font-bold p-3 md:cursor-pointer' onClick={() => handleCopiedToClipboard(song?.isrcCode, 'ISRC Code')}>{song?.isrcCode}</span>
                                                                                                              </p>
                                                                                                    )
                                                                                          }
                                                                                          <p>
                                                                                                    <span className='font-bold'>Submit Date: </span>{moment(song?.createdAt).format('MMM Do YYYY hh:mm:ss A')}
                                                                                          </p>
                                                                                          {
                                                                                                    song?.remixVersionInfo && (
                                                                                                              <p>
                                                                                                                        <span className='font-bold'>Remix Or Version Info: </span>{song?.remixVersionInfo}
                                                                                                              </p>
                                                                                                    )
                                                                                          }
                                                                                          <p>
                                                                                                    <span className='font-bold'>Record Label Name: </span>{song?.recordLabelName}
                                                                                          </p>
                                                                                          <p className='capitalize'>
                                                                                                    <span className='font-bold'>Language: </span>{song?.language}
                                                                                          </p>
                                                                                          <p>
                                                                                                    <span className='font-bold'>Primary Genre: </span>{findGenre(song?.primaryGenre)}
                                                                                          </p>
                                                                                          {
                                                                                                    song?.secondaryGenre && (<p>
                                                                                                              <span className='font-bold'>Secondary Genre: </span>{findGenre(song?.secondaryGenre)}
                                                                                                    </p>)
                                                                                          }
                                                                                          <p>
                                                                                                    <span className='font-bold'>Explicit lyrics? </span>{song?.isExplicit ? 'Yes' : 'No'}
                                                                                          </p>
                                                                                          <p>
                                                                                                    <span className='font-bold'>Instrumental? </span>{song?.isInstrumental ? 'This song is instrumental and contains no lyrics' : 'Yes - This song contains lyrics'}
                                                                                          </p>
                                                                                </div>
                                                                                {
                                                                                          role === 'admin' && (
                                                                                                    <div className='alert flex flex-col gap-1.5 items-start justify-start shadow-lg border rounded-xl order-first md:order-last'>
                                                                                                              <div className='text-start'>
                                                                                                                        <h1 className='font-bold text-xl md:text-2xl'>User Info</h1>
                                                                                                                        <div className="avatar my-5">
                                                                                                                                  <div className="w-32 border-2 border-gradientSecondary rounded-[2.5rem]">
                                                                                                                                            <img src={songUser?.profileImage} alt='' />
                                                                                                                                  </div>
                                                                                                                        </div>
                                                                                                                        <p><span className='font-bold'>Artist Name: </span>{songUser?.artistName}</p>
                                                                                                                        <p><span className='font-bold'>Full Name: </span>{songUser?.firstName} {songUser?.lastName}</p>
                                                                                                                        <p className='flex items-center gap-2'>
                                                                                                                                  <span className='font-bold'>Email: </span><span className='badge bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white font-bold p-3 md:cursor-pointer' onClick={() => handleCopiedToClipboard(songUser?.email, 'User Email')}>{songUser?.email}</span>
                                                                                                                        </p>
                                                                                                                        <p><span className='font-bold'>Last Login: </span>{moment(songUser?.updatedAt).format('MMM Do YYYY hh:mm:ss A')}</p>
                                                                                                                        <button className={`mt-4 btn btn-xs border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white`}
                                                                                                                                  onClick={() => navigate(`/all-artists/${songUser?._id}`)}
                                                                                                                        >
                                                                                                                                  See more
                                                                                                                        </button>
                                                                                                              </div>
                                                                                                    </div>
                                                                                          )
                                                                                }
                                                                      </div>
                                                                      <div className='flex flex-col gap-2 mt-5'>
                                                                                <span className='font-bold'>Display Artists: </span>
                                                                                <div className="border border-gradientPrimary rounded-xl w-full">
                                                                                          <table className="table w-full">
                                                                                                    <thead>
                                                                                                              <tr>
                                                                                                                        <th>Type</th>
                                                                                                                        <th>Artist Name</th>
                                                                                                                        <th>Spotify</th>
                                                                                                                        <th className='hidden md:block'>Apple Music</th>
                                                                                                                        <th className='md:hidden'>Apple</th>
                                                                                                              </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                              {song?.displayArtist?.map((artist: any) => (
                                                                                                                        <tr key={artist?._id}>
                                                                                                                                  <td>{
                                                                                                                                            artist?.type === 'primaryArtist' ?
                                                                                                                                                      'Primary Artist' : ''}
                                                                                                                                  </td>
                                                                                                                                  <td>{artist?.artistName}</td>
                                                                                                                                  <td>
                                                                                                                                            <span className="btn-sm text-lg btn-circle btn-ghost btn btn-outline"
                                                                                                                                                      onClick={() => handleCopiedToClipboard(artist?.spotifyURL, 'Spotify URL')}
                                                                                                                                            >
                                                                                                                                                      <BsSpotify />
                                                                                                                                            </span>
                                                                                                                                  </td>
                                                                                                                                  <td>
                                                                                                                                            <span className="btn-sm text-lg btn-circle btn-ghost btn btn-outline"
                                                                                                                                                      onClick={() => handleCopiedToClipboard(artist?.appleMusicURL, 'Apple Music URL')}
                                                                                                                                            >
                                                                                                                                                      <SiApplemusic />
                                                                                                                                            </span>
                                                                                                                                  </td>
                                                                                                                        </tr>
                                                                                                              ))}
                                                                                                    </tbody>
                                                                                          </table>
                                                                                </div>
                                                                      </div>
                                                                      <div className='flex flex-col gap-2 mt-5'>
                                                                                <span className='font-bold'>Credits: </span>
                                                                                <div className="border border-gradientPrimary rounded-xl w-full">
                                                                                          <table className="table w-full">
                                                                                                    <thead>
                                                                                                              <tr>
                                                                                                                        <th>Type</th>
                                                                                                                        <th>Artist Name</th>
                                                                                                              </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                              {song?.credits?.map((credit: any) => (
                                                                                                                        <tr key={credit?._id}>
                                                                                                                                  <td className='capitalize'>{credit?.type}</td>
                                                                                                                                  <td>{credit?.artistName}</td>
                                                                                                                        </tr>
                                                                                                              ))}
                                                                                                    </tbody>
                                                                                          </table>
                                                                                </div>
                                                                      </div>
                                                                      <div className='flex flex-col gap-2 mt-5'>
                                                                                <span className='font-bold'>Services: </span>
                                                                                <div className="border border-gradientPrimary rounded-xl w-full p-3">
                                                                                          <ul>
                                                                                                    {song?.services?.map((service: any, index: number) => (
                                                                                                              <li key={index} className='capitalize text-sm'>{index + 1}. {findService(service)}</li>
                                                                                                    ))}
                                                                                          </ul>
                                                                                </div>
                                                                      </div>
                                                                      <div className='flex justify-center md:justify-start mx-auto mt-5'>
                                                                                {
                                                                                          role === 'admin' && (
                                                                                                    <button className='btn btn-sm md:btn-md border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white' onClick={() => handleDownload(song?.albumCover, song?.albumCover)}>
                                                                                                              Download Album Cover
                                                                                                    </button>
                                                                                          )
                                                                                }
                                                                      </div>
                                                            </div>
                                                  )
                                        }
                                        {
                                                  activeTab === 1 && (
                                                            <div className='mt-5'>
                                                                      {
                                                                                song?.audioFile ? (
                                                                                          <audio src={song?.audioFile} controls controlsList={'nodownload noplaybackrate'} className='w-full md:w-1/2 lg:w-1/3 mx-auto bg-gray-100 rounded-lg shadow-md' />
                                                                                ) : (
                                                                                          <h1 className='text-center text-xl font-bold'>
                                                                                                    No audio file found
                                                                                          </h1>
                                                                                )
                                                                      }
                                                                      {
                                                                                role === 'admin' && song?.audioFile && (
                                                                                          <DownloadAudio url={song?.audioFile} title={song?.releaseTitle} />
                                                                                )
                                                                      }
                                                            </div>
                                                  )
                                        }
                                        {
                                                  activeTab === 2 && (
                                                            <div className="mt-5">
                                                                      {
                                                                                song?.status === 'pending' && (
                                                                                          <>
                                                                                                    <div className='mb-3'>
                                                                                                              <button className={`btn btn-xs border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white`}
                                                                                                                        onClick={() => setIsShowApproveForm(!isShowApproveForm)}
                                                                                                                        disabled={song?.status === 'approved' || song?.status === 'rejected'}
                                                                                                              >
                                                                                                                        {isShowApproveForm ? 'Hide Approve Form' : 'Show Approve Form'}
                                                                                                              </button>
                                                                                                    </div>
                                                                                                    {
                                                                                                              isShowApproveForm && (
                                                                                                                        <form
                                                                                                                                  onSubmit={handleApprovedSongStatus}
                                                                                                                                  className='name border rounded p-3 relative my-6 flex-1 w-full'
                                                                                                                        >
                                                                                                                                  <div className="name border rounded p-3 relative mt-3 flex-1">
                                                                                                                                            <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                                                                                      <h3 className="text-sm font-bold">UPC Code</h3>
                                                                                                                                            </div>
                                                                                                                                            <div className={`input-group flex items-center my-2 border p-2 rounded-md mt-2`}>
                                                                                                                                                      <input type='text' {...register("upcCode")} className='input form-control focus:outline-none w-full' placeholder='Enter UPC Code' />
                                                                                                                                            </div>
                                                                                                                                  </div>
                                                                                                                                  <div className="name border rounded p-3 relative mt-7 flex-1">
                                                                                                                                            <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                                                                                      <h3 className="text-sm font-bold">ISRC Code</h3>
                                                                                                                                            </div>
                                                                                                                                            <div className={`input-group flex items-center my-2 border p-2 rounded-md mt-2`}>
                                                                                                                                                      <input type='text' {...register("isrcCode")} className='input form-control focus:outline-none w-full' placeholder='Enter ISRC Code' />
                                                                                                                                            </div>
                                                                                                                                  </div>
                                                                                                                                  {approveSongLoading ? (
                                                                                                                                            <button className={`btn btn-xs border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white mt-2`} type="button">
                                                                                                                                                      <PulseLoader size={8} color="#fff" />
                                                                                                                                            </button>
                                                                                                                                  ) : (
                                                                                                                                            <button className={`btn btn-xs border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white mt-2`} type="submit">
                                                                                                                                                      Submit
                                                                                                                                            </button>
                                                                                                                                  )}
                                                                                                                        </form>
                                                                                                              )
                                                                                                    }
                                                                                                    <form
                                                                                                              onSubmit={handleRejectSongStatus}
                                                                                                              className='mb-3'
                                                                                                    >
                                                                                                              {rejectSongLoading ? (
                                                                                                                        <button className={`btn btn-xs border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white mt-2`} type="button">
                                                                                                                                  <PulseLoader size={8} color="#fff" />
                                                                                                                        </button>
                                                                                                              ) : (
                                                                                                                        <button className={`btn btn-xs border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white mt-2`} type="submit">
                                                                                                                                  Reject Song
                                                                                                                        </button>
                                                                                                              )}
                                                                                                    </form>
                                                                                          </>
                                                                                )
                                                                      }
                                                                      {
                                                                                song?.status === 'approved' && (
                                                                                          <>
                                                                                                    <div className='flex items-center gap-3 mb-3'>
                                                                                                              <span className='font-bold'>Takedown Song:
                                                                                                                        <button className={`ml-2 btn btn-xs border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white`}
                                                                                                                                  onClick={() => setIsShowTakedownForm(!isShowTakedownForm)}
                                                                                                                        >
                                                                                                                                  {isShowTakedownForm ? 'Hide Form' : 'Show Form'}
                                                                                                                        </button>
                                                                                                              </span>
                                                                                                    </div>
                                                                                                    {
                                                                                                              isShowTakedownForm && (
                                                                                                                        <div className='w-full'>
                                                                                                                                  <form
                                                                                                                                            onSubmit={handleTakeDownSong}
                                                                                                                                            className="name border rounded p-3 relative mt-10 flex-1"
                                                                                                                                  >
                                                                                                                                            <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                                                                                      <h3 className="text-sm font-bold">Takedown Claimant Email</h3>
                                                                                                                                            </div>
                                                                                                                                            <div className={`input-group flex items-center my-2 border p-2 rounded-md mt-2`}>
                                                                                                                                                      <input type='email' {...register("claimantEmail")} className='input form-control focus:outline-none w-full' placeholder='Enter claimant email' />
                                                                                                                                            </div>

                                                                                                                                            {takedownSongLoading ? (
                                                                                                                                                      <button className={`btn btn-xs border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white mt-2`} type="button">
                                                                                                                                                                <PulseLoader size={8} color="#fff" />
                                                                                                                                                      </button>
                                                                                                                                            ) : (
                                                                                                                                                      <button className={`btn btn-xs border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white mt-2`} type="submit">
                                                                                                                                                                Submit
                                                                                                                                                      </button>
                                                                                                                                            )}
                                                                                                                                  </form>
                                                                                                                        </div>
                                                                                                              )
                                                                                                    }
                                                                                          </>
                                                                                )
                                                                      }
                                                            </div>
                                                  )
                                        }
                              </div>
                    </div>
          )
}
