import { baseApi } from "../baseApi";

export const verifyRequestApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllVerifyRequest: builder.query({
      query: ({ status }) => ({
        url: `/verify-requests?status=${status}`,
        method: "GET",
      }),
      providesTags: ["verifyRequest"],
    }),
    getVerifyRequestByUserId: builder.query({
      query: ({ userId }) => ({
        url: `/verify-requests/user?id=${userId}`,
        method: "GET",
      }),
      providesTags: ["verifyRequest"],
    }),
    addVerifyRequest: builder.mutation({
      query: (body) => ({
        url: `/verify-requests`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["verifyRequest"],
    }),
  }),
});

export const {
  useGetAllVerifyRequestQuery,
  useGetVerifyRequestByUserIdQuery,
  useAddVerifyRequestMutation,
} = verifyRequestApi;
