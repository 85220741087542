import React from 'react'

type Props = {
          handleSearchInfo: (e: any) => void;
}

export default function SearchInfo({ handleSearchInfo }: Props) {
          return (
                    <div className='mb-10'>
                              <div className="form-control w-full">
                                        <div className="flex -mx-3 md:w-full md:max-w-sm">
                                                  <div className="w-full px-3 mb-2">
                                                            <label htmlFor="" className="text-xs font-semibold px-1">Type to search</label>
                                                            <input type='text' id='clear-form' className="input input-bordered w-full mt-1" placeholder="Type here to search..."
                                                                      onChange={handleSearchInfo}
                                                            />
                                                  </div>
                                        </div>
                              </div>
                    </div>
          )
}
