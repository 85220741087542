import moment from "moment";
import { BsYoutube } from "react-icons/bs";
import GlobalLoader from "../../../../components/GlobalLoader";
import MiniTitle from "../../../../components/MiniTitle";
import useAuth from "../../../../hooks/useAuth";
import useTitle from "../../../../hooks/useTitle";
import { authUserInterface } from "../../../../interfaces/UserInterface";
import { useGetClaimRequestByUserQuery } from "../../../../redux/features/ClaimRequestApi";

const Fade = require("react-reveal/Fade");

export default function AllClaimReleases() {
          useTitle("All Claim Releases");

          const {
                    updatedUser,
          } = useAuth<authUserInterface | any>({});

          const { data, isLoading } = useGetClaimRequestByUserQuery({ userId: updatedUser?._id });

          const claimRequests = data?.data;

          if (isLoading) {
                    return <GlobalLoader />
          }

          return (
                    <Fade top distance="20px">
                              <div className="py-0 md:pb-5 lg:py-5 rounded-md">
                                        <MiniTitle title="All Claim Releases" />
                                        {
                                                  claimRequests?.length > 0 ? (
                                                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                                                                      {
                                                                                claimRequests?.map((claim: any, index: number) => (
                                                                                          <div key={index} className="border card rounded-xl shadow-md">
                                                                                                    <div className="card-body">
                                                                                                              <p className="text-gray-500">UPC Code: <span className="badge bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white p-3">{claim?.upcCode}</span></p>
                                                                                                              <p className="text-gray-500 flex items-center gap-2">Youtube URL: <a href={claim?.ytUrl}><BsYoutube size={20} /></a></p>
                                                                                                              <small className="text-gray-500">Requested at: {moment(claim?.createdAt).format("Do MMM YYYY, hh:mm:ss A")}</small>
                                                                                                    </div>
                                                                                          </div>
                                                                                ))
                                                                      }
                                                            </div>
                                                  ) : (
                                                            <div className="text-center text-gray-500">
                                                                      <p>No claim releases found.</p>
                                                            </div>
                                                  )
                                        }
                              </div>
                    </Fade>
          )
}