import { Outlet, useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useTitle from '../../../hooks/useTitle';
import { authUserInterface } from '../../../interfaces/UserInterface';

export default function Setting() {
          useTitle('Setting');

          const {
                    updatedUser
          } = useAuth<authUserInterface | any>({});

          const role = updatedUser?.role;

          const navigate = useNavigate();

          let tabs = [
                    {
                              id: 1,
                              title: 'Change Password',
                              link: 'change-password',
                              roles: ['admin', 'user']
                    },
                    {
                              id: 2,
                              title: 'YT OAC Request',
                              link: 'yt-oac-request',
                              roles: ['admin']
                    },
                    {
                              id: 3,
                              title: 'Spotify Verify Request',
                              link: 'spotify-verify-request',
                              roles: ['admin']
                    }
          ];

          return (
                    <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
                              <div className="title my-2 md:mb-5">
                                        <h3 className="text-2xl font-bold">Setting</h3>
                              </div>
                              <div className="tabs tabs-boxed mt-5 gap-2 bg-transparent duration-300">
                                        {tabs.map((tab, i) => (
                                                  <span key={i} className={`tab md:tab-lg border 
                                                  ${tab.link === window.location.pathname.split('/')[2] ? 'tab-active' : ''}
                                                  ${role && !tab.roles.includes(role) ? 'hidden' : ''}
                                                  ${window.location.pathname === '/setting' && tab.title === 'Change Password' ? 'tab-active' : ''}
                                                  `}
                                                            onClick={() => navigate(`/setting/${tab.link}`)
                                                            }>
                                                            {tab.title}
                                                  </span>
                                        ))}
                              </div>
                              <div>
                                        <Outlet />
                              </div>
                    </div>
          )
}
