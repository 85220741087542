import { HiOutlineClock } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import Banner from '../../../assets/dash-music.jpg'
import GlobalLoader from '../../../components/GlobalLoader'
import useScrollToTop from '../../../hooks/useScrollToTop'
import useTitle from '../../../hooks/useTitle'
import { authUserInterface } from '../../../interfaces/UserInterface'
import { useGetAllSongsByStatusQuery } from '../../../redux/features/SongApi'
import { useGetAllWaitingUserByStatusQuery } from '../../../redux/features/WaitingUserApi'
import { useGetAllPendingWalletRequestsQuery } from '../../../redux/features/WalletApi'

type Props = {
  updatedUser: authUserInterface | any;
}

const Fade = require("react-reveal/Fade");

export default function AdminDashboard({ updatedUser }: Props) {
  useTitle('Dashboard');
  useScrollToTop();

  const { data, isLoading } = useGetAllWaitingUserByStatusQuery({ status: 'pending' });

  const applicants = data?.data;

  const { data: songsData, isLoading: songIsLoading } = useGetAllSongsByStatusQuery({ status: 'pending' });

  const songs = songsData?.data;

  const { data: walletData, isLoading: walletIsLoading } = useGetAllPendingWalletRequestsQuery({});

  const wallets = walletData?.data;

  if (isLoading || songIsLoading || walletIsLoading) {
    return <GlobalLoader />
  }

  return (
    <div className="py-0 pb-5 md:pb-5">
      <div className='mt-3 relative'>
        <img src={Banner} alt="Mirror-LTD Music" draggable={false} className="select-none w-full h-60 md:h-96 lg:h-[25rem] object-cover rounded-xl" />
        <div className='absolute bottom-5 md:bottom-10 left-5 md:left-10'>
          <h1 className='text-lg md:text-2xl mb-2 font-bold text-white pr-5 md:pr-0'>Welcome back Chief <Link to={'/profile'}>{updatedUser?.firstName + " " + updatedUser?.lastName}</Link>, Ready for track your artists?</h1>
          <p className='font-semibold mb-3 text-sm md:text-[16px] md:mb-5 text-white'>Manage applications and artists</p>
          <div className='flex items-center gap-4'>
            <Link to={'/applications'} className='btn btn-xs md:btn-sm border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary rounded-full text-white'>Applications</Link>
            <Link to={'/all-artists'} className='btn btn-xs md:btn-sm border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary rounded-full text-white'>Artists</Link>
          </div>
        </div>
      </div>
      <div className='mt-5 md:mt-10'>
        <h1 className='font-bold text-lg md:text-xl flex items-center gap-2 text-black'><HiOutlineClock />Pending Applications</h1>
        <Fade top distance="20px">
          {
            applicants?.length > 0 ? (
              <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-3'>
                {
                  applicants?.slice(0, 4)?.map((applicant: any) => (
                    <Link to={`/applications/${applicant?._id}`} key={applicant._id} className={`relative w-full rounded-xl shadow-md flex flex-col group`}>
                      <div className="w-full overflow-hidden rounded-xl glass">
                        <img
                          className="w-full h-32 md:h-48 lg:h-52 object-cover md:group-hover:scale-110 duration-300"
                          src={'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'}
                          alt={applicant?.firstName}
                          loading='lazy'
                        />
                      </div>
                      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#ffffff00] to-black opacity-100 transition-opacity duration-300 ease-in-out rounded-xl"></div>
                      <div className="absolute bottom-0 left-0 p-4 text-white opacity-100 transition-opacity duration-300 ease-in-out rounded-xl">
                        <h3 className="hidden md:flex text-base md:text-lg font-bold">
                          {applicant?.firstName + " " + applicant?.lastName}
                        </h3>
                        <h3 className="md:hidden text-base md:text-lg font-bold">
                          {(applicant?.firstName + " " + applicant?.lastName)?.length > 12 ? (applicant?.firstName + " " + applicant?.lastName)?.slice(0, 12) + '...' : applicant?.firstName + " " + applicant?.lastName}
                        </h3>
                        <p className='text-[10px] md:text-sm font-semibold hidden md:block'>{applicant?.email}</p>
                        <p className='text-[10px] md:text-sm font-semibold md:hidden'>
                          {applicant?.email?.length > 20 ? applicant?.email?.slice(0, 20) + '...' : applicant?.email}
                        </p>
                      </div>
                    </Link>
                  ))
                }
              </div>
            ) : (
              <div>
                <h1 className='text-center text-sm mt-5'>No Pending Applications</h1>
                <div className='flex justify-center mt-3'>
                  <Link to={'/applications'} className='btn btn-xs md:btn-sm border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary rounded-full text-white'>View All Applications</Link>
                </div>
              </div>
            )
          }
        </Fade>
      </div>
      <div className='mt-5 md:mt-10'>
        <h1 className='font-bold text-lg md:text-xl flex items-center gap-2 text-black'><HiOutlineClock />Pending Releases</h1>
        <Fade top distance="20px">
          {
            songs?.length > 0 ? (
              <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-3'>
                {
                  songs?.slice(0, 3)?.map((release: any) => (
                    <Link to={`/pending-all-releases/${release._id}`} key={release._id} className={`relative w-full rounded-xl shadow-md flex flex-col group`}>
                      <div className="w-full overflow-hidden rounded-xl glass">
                        <img
                          className="w-full h-32 md:h-48 lg:h-52 object-cover md:group-hover:scale-110 duration-300"
                          src={release?.albumCover}
                          alt={release?.releaseTitle}
                          loading='lazy'
                        />
                      </div>
                      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#ffffff00] to-black opacity-100 transition-opacity duration-300 ease-in-out rounded-xl"></div>
                      <div className="absolute bottom-0 left-0 p-4 text-white opacity-100 transition-opacity duration-300 ease-in-out rounded-xl">
                        <h3 className="hidden md:flex text-base md:text-lg font-bold">
                          {release?.releaseTitle?.length > 25 ? release?.releaseTitle?.slice(0, 25) + '...' : release?.releaseTitle}
                        </h3>
                        <h3 className="md:hidden text-base md:text-lg font-bold">
                          {release?.releaseTitle?.length > 12 ? release?.releaseTitle?.slice(0, 12) + '...' : release?.releaseTitle}
                        </h3>
                        <p className='uppercase text-[10px] md:text-sm font-semibold'>{release?.displayArtist[0]?.artistName}</p>
                      </div>
                    </Link>
                  ))
                }
              </div>
            ) : (
              <div className="card flex justify-center items-center mx-auto w-full md:w-2/3 lg:w-1/3 mt-5">
                <div className="card-body">
                  <h1 className='text-center text-sm'>
                    No pending releases found!
                  </h1>
                </div>
              </div>
            )
          }
        </Fade>
      </div>
      <div className='mt-5 md:mt-10'>
        <h1 className='font-bold text-lg md:text-xl flex items-center gap-2 text-black'><HiOutlineClock />Pending Withdrawal</h1>
        <Fade top distance="20px">
          {
            wallets?.length > 0 ? (
              <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-3'>
                {
                  wallets?.map((wallet: any) => (
                    <Link to={`/all-artists/${wallet.userId}`} key={wallet._id} className={`relative w-full rounded-xl shadow-md flex flex-col group`}>
                      <div className="w-full overflow-hidden rounded-xl glass">
                        <img
                          className="w-full h-32 md:h-48 lg:h-52 object-cover md:group-hover:scale-110 duration-300"
                          src={wallet?.user?.profile}
                          alt={wallet?.user?.firstName}
                          loading='lazy'
                        />
                      </div>
                      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#ffffff00] to-black opacity-100 transition-opacity duration-300 ease-in-out rounded-xl"></div>
                      <div className="absolute bottom-0 left-0 p-4 text-white opacity-100 transition-opacity duration-300 ease-in-out rounded-xl">
                        <h3 className="text-xl md:text-2xl font-bold">
                          $ {wallet?.amount}
                        </h3>
                        <p className='text-xs md:text-sm font-semibold'>{wallet?.user?.firstName} {wallet?.user?.lastName}</p>
                        <p className='hidden md:flex text-[10px] md:text-sm font-semibold'>{wallet?.user?.email}</p>
                        <p className='md:hidden text-[10px] md:text-sm font-semibold'>{wallet?.user?.email?.length > 25 ? wallet?.user?.email?.slice(0, 25) + '...' : wallet?.user?.email}</p>
                      </div>
                    </Link>
                  ))
                }
              </div>
            ) : (
              <div className="card flex justify-center items-center mx-auto w-full md:w-2/3 lg:w-1/3 mt-5">
                <div className="card-body">
                  <h1 className='text-center text-sm'>
                    No pending withdrawals found!
                  </h1>
                </div>
              </div>
            )
          }
        </Fade>
      </div>
    </div>
  )
}
