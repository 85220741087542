import { useEffect, useState } from 'react';
import GlobalLoader from '../../../../components/GlobalLoader';
import SearchInfo from '../../../../components/SearchInfo';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import useTitle from '../../../../hooks/useTitle';
import { useGetOnlyUsersQuery } from '../../../../redux/features/auth/AuthApi';
import UsersCard from './UsersCard';

const Fade = require("react-reveal/Fade");

export default function Users() {
          useTitle("Users");
          useScrollToTop();

          const { data, isLoading, refetch } = useGetOnlyUsersQuery({});

          const users = data?.data;

          const [searchedData, setSearchedData] = useState<any[]>([]);

          useEffect(() => {
                    if (!searchedData?.length) {
                              setSearchedData(users);
                              refetch();
                    }
          }, [searchedData, users, refetch]);

          const handleSearchInfo = async (e: any) => {
                    e.preventDefault();
                    const value = e.target.value.toLowerCase();
                    const filter = users?.filter((item: any) => {
                              return (
                                        item?.firstName?.toLowerCase()?.includes(value) ||
                                        item?.lastName?.toLowerCase()?.includes(value) ||
                                        item?.email?.toLowerCase()?.includes(value) ||
                                        item?.phoneNumber?.toLowerCase()?.includes(value)
                              );
                    });
                    const getData = setTimeout(() => {
                              setSearchedData(filter);
                    }, 1000);

                    return () => clearTimeout(getData);
          };

          if (isLoading) {
                    return <GlobalLoader />
          }

          return (
                    <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
                              <div className="title my-2 mb-5">
                                        <h3 className="text-2xl font-bold">Manage All Artists ({data?.data?.length})</h3>
                                        <span>You can manage all the artists whom are already registered</span>
                              </div>
                              <SearchInfo handleSearchInfo={handleSearchInfo} />
                              <Fade top distance="20px">
                                        {
                                                  searchedData?.length > 0 ? (
                                                            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 pb-10'>
                                                                      {
                                                                                searchedData?.map((user: any, index: number) => (
                                                                                          <UsersCard key={user._id} index={index} user={user} isLoading={isLoading} />
                                                                                ))
                                                                      }
                                                            </div>
                                                  ) : (
                                                            <div className="card flex flex-col justify-center items-center mx-auto w-full md:w-[300px] mt-24">
                                                                      <div className="card-body">
                                                                                <h1 className='text-center text-sm text-red-600'>
                                                                                          Oops! No artists found
                                                                                </h1>
                                                                      </div>
                                                            </div>
                                                  )
                                        }
                              </Fade>
                    </div>
          );
};