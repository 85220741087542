import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
          BiEnvelope,
          BiMailSend,
          BiPhoneIncoming,
          BiUser,
} from "react-icons/bi";
import { BsFacebook, BsYoutube } from "react-icons/bs";
import { PulseLoader } from "react-spinners";
import useAuth from "../../../hooks/useAuth";
import { authUserInterface } from "../../../interfaces/UserInterface";
import { useUpdateProfileMutation } from "../../../redux/features/auth/AuthApi";

type Props = {
          refetch: () => void;
};

const ProfileModal = ({ refetch }: Props) => {
          const { updatedUser } = useAuth<authUserInterface | any>({});
          const [updateProfile, { data, isSuccess, isLoading }] =
                    useUpdateProfileMutation();

          const fullName = `${updatedUser.firstName} ${updatedUser.lastName}`

          const { register, handleSubmit, reset, setValue } = useForm();

          const handleUpdateProfile = handleSubmit(async (formData) => {
                    if (formData?.phoneNumber?.length < 11 || formData?.phoneNumber?.length > 11) {
                              return toast.error("Phone number must be 11 digits");
                    }

                    const bodyData = { ...formData, email: updatedUser?.email };
                    await updateProfile(bodyData);
          });

          useEffect(() => {
                    setValue("phoneNumber", updatedUser?.phoneNumber);
                    setValue("address", updatedUser?.address);
                    setValue("zipCode", updatedUser?.zipCode);
                    setValue("city", updatedUser?.city);
                    setValue("country", updatedUser?.country);
                    setValue("fbLink", updatedUser?.fbLink);
                    setValue("ytLink", updatedUser?.ytLink);

                    if (isSuccess) {
                              toast.success((data as any).message);
                              reset();
                              refetch();
                    }
          }, [isSuccess, data, setValue, updatedUser, refetch, reset]);

          return (
                    <form onSubmit={handleUpdateProfile}>
                              <input type="checkbox" id="profile-edit-modal" className="modal-toggle" />
                              <div className={`modal modal-bottom sm:modal-middle`}>
                                        <div className="modal-box md:w-5/6 lg:w-1/3 md:max-w-5xl">
                                                  <h3 className="font-bold text-xl">Edit Profile Information</h3>
                                                  <div className="modal-body">
                                                            <div className="name border rounded p-3 relative mt-10 flex-1">
                                                                      <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                <h3 className="text-xs font-poppins">Full Name</h3>
                                                                      </div>
                                                                      <div className="flex items-center gap-3">
                                                                                <div className={`input-group flex items-center my-2 border p-3 tooltip rounded-md mt-2 bg-base-300`}
                                                                                          data-tip="Readonly"
                                                                                >
                                                                                          <div className="icon">
                                                                                                    <BiUser />
                                                                                          </div>
                                                                                          <input
                                                                                                    type="text"
                                                                                                    className={`form-control outline-none pl-4 w-full bg-base-300`}
                                                                                                    placeholder="Name"
                                                                                                    defaultValue={fullName}
                                                                                                    readOnly
                                                                                          />
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                            <div className="name border rounded p-3 relative mt-10 flex-1">
                                                                      <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                <h3 className="text-xs font-poppins">Artist Bio</h3>
                                                                      </div>
                                                                      <div className={`input-group flex items-center my-2 border p-3 tooltip rounded-md mt-2 bg-base-300`}
                                                                                data-tip="Readonly"
                                                                      >
                                                                                <div className="icon">
                                                                                          <BiUser />
                                                                                </div>
                                                                                <textarea
                                                                                          typeof="text"
                                                                                          className={`form-control outline-none pl-4 w-full bg-base-300`}
                                                                                          placeholder="Artist Bio"
                                                                                          style={{ height: "150px", resize: "none" }}
                                                                                          defaultValue={updatedUser.artistBio}
                                                                                          readOnly
                                                                                />
                                                                      </div>
                                                            </div>
                                                            <div className="flex flex-col md:flex-row md:gap-2">
                                                                      <div className="name border rounded p-3 relative mt-10 flex-1">
                                                                                <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                          <h3 className="text-xs font-poppins">Email</h3>
                                                                                </div>
                                                                                <div
                                                                                          className="input-group flex items-center my-2 border p-3 rounded-md mt-2 bg-base-300 tooltip"
                                                                                          data-tip="Readonly"
                                                                                >
                                                                                          <div className="icon">
                                                                                                    <BiEnvelope />
                                                                                          </div>
                                                                                          <input
                                                                                                    type="email"
                                                                                                    className="form-control outline-none pl-4 w-full bg-base-300"
                                                                                                    placeholder="Email"
                                                                                                    defaultValue={updatedUser.email}
                                                                                                    readOnly
                                                                                          />
                                                                                </div>
                                                                      </div>
                                                                      <div className="name border rounded p-3 relative mt-10 flex-1">
                                                                                <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                          <h3 className="text-xs font-poppins">Phone Number</h3>
                                                                                </div>
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                                          <div className="icon">
                                                                                                    <BiPhoneIncoming />
                                                                                          </div>
                                                                                          <input
                                                                                                    type="number"
                                                                                                    className={`form-control outline-none pl-4 w-full`}
                                                                                                    placeholder="Phone Number"
                                                                                                    defaultValue={updatedUser.phoneNumber}
                                                                                                    {...register("phoneNumber")}
                                                                                          />
                                                                                </div>
                                                                      </div>
                                                            </div>

                                                            <div className="name border  rounded p-3 pb-1 relative mt-10 flex-1">
                                                                      <div className="flex flex-col md:flex-row md:gap-3">
                                                                                <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                          <h3 className="text-xs font-poppins flex items-center gap-3">
                                                                                                    Address
                                                                                          </h3>
                                                                                </div>
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                                                                                          data-tip="Readonly"
                                                                                >
                                                                                          <div className="icon">
                                                                                                    <BiMailSend />
                                                                                          </div>
                                                                                          <input
                                                                                                    type="text"
                                                                                                    className={`form-control outline-none pl-4 w-full`}
                                                                                                    placeholder="House No, Road No, Area"
                                                                                                    {...register("address")}
                                                                                                    defaultValue={updatedUser?.address}
                                                                                                    readOnly={updatedUser?.role === "admin" ? true : false}
                                                                                          />
                                                                                </div>
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                                                                                          data-tip="Readonly"
                                                                                >
                                                                                          <div className="icon">
                                                                                                    <BiMailSend />
                                                                                          </div>
                                                                                          <input
                                                                                                    type="text"
                                                                                                    className={`form-control outline-none pl-4 w-full`}
                                                                                                    placeholder="Zip Code"
                                                                                                    {...register("zipCode")}
                                                                                                    defaultValue={updatedUser?.zipCode}
                                                                                                    readOnly={updatedUser?.role === "admin" ? true : false}
                                                                                          />
                                                                                </div>
                                                                      </div>
                                                                      <div className="flex flex-col md:flex-row items-center md:gap-3">
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                                                                                          data-tip="Readonly"
                                                                                >
                                                                                          <div className="icon">
                                                                                                    <BiMailSend />
                                                                                          </div>
                                                                                          <input
                                                                                                    type="text"
                                                                                                    className={`form-control outline-none pl-4 w-full`}
                                                                                                    placeholder="City"
                                                                                                    {...register("city")}
                                                                                                    defaultValue={updatedUser?.city}
                                                                                                    readOnly={updatedUser?.role === "admin" ? true : false}
                                                                                          />
                                                                                </div>
                                                                                <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                                                                                          data-tip="Readonly"
                                                                                >
                                                                                          <div className="icon">
                                                                                                    <BiMailSend />
                                                                                          </div>
                                                                                          <input
                                                                                                    type="text"
                                                                                                    className={`form-control outline-none pl-4 w-full`}
                                                                                                    placeholder="Country"
                                                                                                    {...register("country")}
                                                                                                    defaultValue={updatedUser?.country}
                                                                                                    readOnly={updatedUser?.role === "admin" ? true : false}
                                                                                          />
                                                                                </div>
                                                                      </div>
                                                            </div>
                                                            <div className="name border rounded p-3 relative mt-10 flex-1">
                                                                      <div className="name-title absolute -top-4 bg-white border rounded p-1">
                                                                                <h3 className="text-xs font-poppins">Social Links</h3>
                                                                      </div>
                                                                      <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                                                                                data-tip="Readonly"
                                                                      >
                                                                                <div className="icon">
                                                                                          <BsFacebook />
                                                                                </div>
                                                                                <input
                                                                                          type="url"
                                                                                          className={`form-control outline-none pl-4 w-full`}
                                                                                          placeholder="Facebook URL"
                                                                                          {...register("fbLink")}
                                                                                          defaultValue={updatedUser?.fbLink}
                                                                                          readOnly={updatedUser?.role === "admin" ? true : false}
                                                                                />
                                                                      </div>
                                                                      <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                                                                                data-tip="Readonly"
                                                                      >
                                                                                <div className="icon">
                                                                                          <BsYoutube />
                                                                                </div>
                                                                                <input
                                                                                          type="url"
                                                                                          className={`form-control outline-none pl-4 w-full`}
                                                                                          placeholder="YouTube Channel URL"
                                                                                          {...register("ytLink")}
                                                                                          defaultValue={updatedUser?.ytLink}
                                                                                          readOnly={updatedUser?.role === "admin" ? true : false}
                                                                                />
                                                                      </div>
                                                            </div>
                                                  </div>
                                                  <div className="modal-action">
                                                            <label htmlFor="profile-edit-modal" className="btn btn-sm md:btn-md btn-error text-white">
                                                                      Cancel
                                                            </label>
                                                            {isLoading ? (
                                                                      <button className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary" type="button">
                                                                                <PulseLoader size={8} color="#fff" />
                                                                      </button>
                                                            ) : (
                                                                      <button className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white" type="submit">
                                                                                Update
                                                                      </button>
                                                            )}
                                                  </div>
                                        </div>
                              </div>
                    </form>
          );
};

export default ProfileModal;