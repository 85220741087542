import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { IoTextOutline } from 'react-icons/io5';
import { PulseLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import MiniTitle from '../../../../components/MiniTitle';
import useAuth from '../../../../hooks/useAuth';
import useTitle from '../../../../hooks/useTitle';
import { authUserInterface } from '../../../../interfaces/UserInterface';
import { useAddVerifyRequestMutation } from '../../../../redux/features/VerifyRequestApi';

export default function YtOACRequest() {
          useTitle('YouTube OAC Request');

          const {
                    updatedUser: user,
                    refetch,
          } = useAuth<authUserInterface | any>({});

          const { register, handleSubmit, reset } = useForm();

          const [addVerifyRequest, { data, isSuccess, isLoading, error }] = useAddVerifyRequestMutation();

          const onSubmit = async (data: any) => {
                    if (!data?.youtubeChannelName || !data?.youtubeChannelUrl || !data?.youtubeTopicChannelUrl) {
                              return toast.error('Please fill up all the fields!');
                    }

                    const newData = {
                              ...data,
                              userId: user?._id,
                              type: 'youtube-oac'
                    }

                    Swal.fire({
                              title: 'Are you sure?',
                              text: "You want to request for YouTube OAC!",
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, Request!'
                    }).then(async (result) => {
                              if (result.isConfirmed) {
                                        await addVerifyRequest(newData);
                              }
                    })
          }

          useEffect(() => {
                    if (isSuccess) {
                              Swal.fire({
                                        title: 'Success!',
                                        icon: 'success',
                                        titleText: 'Success',
                                        text: `Hey, ${user?.firstName}! Your request has been submitted successfully.`,
                                        showConfirmButton: false,
                                        timer: 2000,
                              })
                              refetch();
                              reset();
                    }
                    if (error) {
                              toast.error((error as any)?.data?.message);
                    }
          }, [data, isSuccess, error, refetch, reset, user?.firstName]);

          return (
                    <div className="md:pb-5 py-5 rounded-md">
                              <MiniTitle title='YouTube OAC Request' />
                              <form onSubmit={handleSubmit(onSubmit)} className='pt-10'>
                                        <div className="name border rounded p-3 relative flex-1">
                                                  <div className="name-title absolute -top-5 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">YouTube Channel Name <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  <div className="flex items-center gap-3">
                                                            <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                      <div className="icon">
                                                                                <IoTextOutline />
                                                                      </div>
                                                                      <input
                                                                                type="text"
                                                                                className={`form-control outline-none pl-4 w-full text-sm`}
                                                                                placeholder="Enter YouTube Channel Name"
                                                                                {...register("youtubeChannelName")}
                                                                      />
                                                            </div>
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-5 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">YouTube Channel Url <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  <div className="flex items-center gap-3">
                                                            <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                      <div className="icon">
                                                                                <IoTextOutline />
                                                                      </div>
                                                                      <input
                                                                                type="url"
                                                                                className={`form-control outline-none pl-4 w-full text-sm`}
                                                                                placeholder="Enter YouTube Channel Url"
                                                                                {...register("youtubeChannelUrl")}
                                                                      />
                                                            </div>
                                                  </div>
                                        </div>
                                        <div className="name border rounded p-3 relative mt-10 flex-1">
                                                  <div className="name-title absolute -top-5 bg-white border rounded p-1">
                                                            <h3 className="text-sm font-bold">YouTube Topic Channel Url <span className='text-red-600'>*</span></h3>
                                                  </div>
                                                  <div className="flex items-center gap-3">
                                                            <div className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}>
                                                                      <div className="icon">
                                                                                <IoTextOutline />
                                                                      </div>
                                                                      <input
                                                                                type="url"
                                                                                className={`form-control outline-none pl-4 w-full text-sm`}
                                                                                placeholder="Enter YouTube Topic Channel Url"
                                                                                {...register("youtubeTopicChannelUrl")}
                                                                      />
                                                            </div>
                                                  </div>
                                                  <div className='bg-gray-200 px-3 py-1 rounded-lg'>
                                                            <span className='text-xs'>Channel that is created for YouTube Music. <br /> Example: {user?.artistName || user?.firstName} - Topic</span>
                                                  </div>
                                        </div>

                                        <div className='mt-5 flex justify-center items-center'>
                                                  {isLoading ? (
                                                            <button className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary" type="button">
                                                                      <PulseLoader size={8} color="#fff" />
                                                            </button>
                                                  ) : (
                                                            <button className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white" type="submit">
                                                                      Submit
                                                            </button>
                                                  )}
                                        </div>
                              </form>
                    </div>
          )
}
