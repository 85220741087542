import React from 'react'
import { FaRegLightbulb } from 'react-icons/fa'

export default function AlbumCoverGuideline() {
          return (
                    <div>
                              <input
                                        type="checkbox"
                                        id="album-cover-guideline"
                                        className="modal-toggle"
                              />
                              <div className="modal modal-bottom sm:modal-middle">
                                        <div className="modal-box">
                                                  <div className="modal-body">
                                                            <div className='w-full'>
                                                                      <p className='flex items-start gap-3'>
                                                                                <span className='mt-1 text-warning'><FaRegLightbulb /></span>Recommended images are 3000x3000 square JPG format. This is just a recommendation though—we accept most image sizes.
                                                                      </p>
                                                                      <p className='flex items-start gap-3'>
                                                                                <span className='mt-1 text-warning'><FaRegLightbulb /></span>Stores will reject artwork that contains a website address (URL), Twitter name, or any image that's pixelated, rotated, or poor quality. They'll also reject artwork with prices or store logos (don't put an iTunes or Spotify logo on your artwork). Also, please don't reuse the same artwork for multiple albums.
                                                                      </p>
                                                                      <p className='flex items-start gap-3'>
                                                                                <span className='mt-1 text-warning'><FaRegLightbulb /></span>You own this artwork and everything in it. Stores will reject your artwork if it contains images you found online that you don't have the explicit right to use.
                                                                      </p>
                                                            </div>
                                                  </div>
                                        </div>
                                        <label className="modal-backdrop" htmlFor="album-cover-guideline">Close</label>
                              </div>
                    </div>
          )
}
