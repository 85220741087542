import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GlobalLoader from '../../../../components/GlobalLoader';
import MiniTitle from '../../../../components/MiniTitle';
import SearchInfo from '../../../../components/SearchInfo';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import useTitle from '../../../../hooks/useTitle';
import { useGetAllSongsByStatusQuery } from '../../../../redux/features/SongApi';

const Fade = require("react-reveal/Fade");

export default function PendingAllReleases() {
          useTitle('Pending Releases');
          useScrollToTop();

          const { data, isLoading, refetch } = useGetAllSongsByStatusQuery({ status: 'pending' });

          const songs = data?.data;

          const [searchedData, setSearchedData] = useState<any[]>([]);

          useEffect(() => {
                    if (!searchedData?.length) {
                              setSearchedData(songs);
                              refetch();
                    }
          }, [searchedData, songs, refetch]);

          const handleSearchInfo = async (e: any) => {
                    e.preventDefault();
                    const value = e.target.value.toLowerCase();
                    const filter = songs?.filter((item: any) => {
                              return (
                                        item?.releaseTitle?.toLowerCase()?.includes(value)
                              );
                    });
                    const getData = setTimeout(() => {
                              setSearchedData(filter);
                    }, 1000);

                    return () => clearTimeout(getData);
          };

          if (isLoading) {
                    return <GlobalLoader />
          }

          return (
                    <Fade top distance="20px">
                              <div className="py-0 md:pb-5 lg:py-5 rounded-md">
                                        <MiniTitle title='Pending releases' />
                                        <SearchInfo handleSearchInfo={handleSearchInfo} />
                                        {
                                                  searchedData?.length > 0 ? (
                                                            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5'>
                                                                      {
                                                                                searchedData?.map((release: any) => (
                                                                                          <Link to={`/pending-all-releases/${release._id}`} key={release._id} className={`relative w-full rounded-xl shadow-md flex flex-col group`}>
                                                                                                    <div className="w-full overflow-hidden rounded-xl glass">
                                                                                                              <img
                                                                                                                        className="w-full h-32 md:h-48 lg:h-52 object-cover md:group-hover:scale-110 duration-300"
                                                                                                                        src={release?.albumCover}
                                                                                                                        alt={release?.releaseTitle}
                                                                                                                        loading='lazy'
                                                                                                              />
                                                                                                    </div>
                                                                                                    <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#ffffff00] to-black opacity-100 transition-opacity duration-300 ease-in-out rounded-xl"></div>
                                                                                                    <div className="absolute bottom-0 left-0 p-4 text-white opacity-100 transition-opacity duration-300 ease-in-out rounded-xl">
                                                                                                              <h3 className="text-base md:text-lg font-bold md:hidden">
                                                                                                                        {release?.releaseTitle?.length > 10 ? release?.releaseTitle?.slice(0, 10) + '...' : release?.releaseTitle}
                                                                                                              </h3>
                                                                                                              <h3 className="text-base md:text-lg font-bold hidden md:block">
                                                                                                                        {release?.releaseTitle}
                                                                                                              </h3>
                                                                                                              <p className='uppercase text-xs md:text-sm font-semibold'>{release?.displayArtist[0]?.artistName}</p>
                                                                                                    </div>
                                                                                          </Link>
                                                                                ))
                                                                      }
                                                            </div>
                                                  ) : (
                                                            <div className="card flex flex-col justify-center items-center mx-auto w-full md:w-[300px] mt-24">
                                                                      <div className="card-body">
                                                                                <h1 className='text-center text-sm text-red-600'>
                                                                                          Oops! No pending songs found
                                                                                </h1>
                                                                      </div>
                                                            </div>
                                                  )
                                        }
                              </div>
                    </Fade>
          )
}
