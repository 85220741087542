import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import useAuth from '../../hooks/useAuth';
import useTitle from '../../hooks/useTitle';
import { authUserInterface } from '../../interfaces/UserInterface';
import { useChangePasswordWithoutOldPwdMutation } from '../../redux/features/auth/AuthApi';

export default function PasswordChangeField() {
          useTitle("Update Password");
          const { user } = useAuth<authUserInterface | any>({});
          const { verified } = useParams();
          const navigate = useNavigate();
          const [isShow, setIsShow] = useState<boolean>(false);
          const [ChangePassword, { data, error, isSuccess, isLoading, isError }] =
                    useChangePasswordWithoutOldPwdMutation();


          const { register, handleSubmit, reset } = useForm();

          const handleResetPassword = handleSubmit(async (formData) => {
                    if (!formData?.newPassword) return toast.error('New Password is required');
                    if (!formData?.confirmPassword) return toast.error('Confirm Password is required');
                    if (formData?.newPassword !== formData?.confirmPassword) return toast.error('New Password and Confirm Password must be same');
                    await ChangePassword({ newPassword: formData?.newPassword, id: verified });
          })

          useEffect(() => {
                    if (user?.isAuthenticated) {
                              navigate("/");
                    }

                    if (isError) {
                              toast.error((error as any)?.data?.message);
                    }

                    if (isSuccess) {
                              toast.success(data?.message + " Now you may login this password.");
                              navigate("/login");
                              reset();
                    }
          }, [verified, navigate, user, isSuccess, data, reset, error, isError]);

          return (
                    <div className='bg-[url("./assets/bg-light.jpg")] bg-cover flex justify-center items-center h-screen'>
                              <div className="card w-full md:w-2/3 lg:w-1/3 glass mx-3 md:mx-0">
                                        <div className="card-body">
                                                  <h2 className="card-title">Change your default password to enter dashboard</h2>
                                                  <p>Password is very secure things for everyone. Which one be any
                                                            credential password. So please secure it as like your valuable things.</p>
                                                  <form onSubmit={handleResetPassword} className='mt-4'>
                                                            <div className="form-control w-full relative">
                                                                      <label className="label">
                                                                                <span className="label-text">New Password</span>
                                                                      </label>
                                                                      <input
                                                                                {...register("newPassword")}
                                                                                type={isShow ? "text" : "password"}
                                                                                placeholder="New Password" className="md:py-7 input input-bordered w-full bg-transparent" />
                                                                      <div
                                                                                className="eye absolute right-6 top-[3.25rem] md:top-[3.7rem] cursor-pointer z-10 select-none"
                                                                                onClick={() => setIsShow((state) => !state)}
                                                                      >
                                                                                {isShow ? <FaRegEye /> : <FaRegEyeSlash />}
                                                                      </div>
                                                            </div>
                                                            <div className="form-control w-full mt-4 mb-6">
                                                                      <label className="label">
                                                                                <span className="label-text">Confirm Password</span>
                                                                      </label>
                                                                      <input
                                                                                {...register("confirmPassword")}
                                                                                type={isShow ? "text" : "password"}
                                                                                placeholder="Confirm Password" className="md:py-7 input input-bordered w-full bg-transparent" />
                                                            </div>
                                                            {isLoading ? (
                                                                      <button className="btn btn-sm md:btn-md flex justify-center items-center mx-auto bg-gradient-to-l from-gradientSecondary to-gradientPrimary mt-6 md:px-6 text-white" disabled>
                                                                                <PulseLoader color="#fff" size={6} />
                                                                      </button>
                                                            ) : (
                                                                      <button className="btn btn-sm md:btn-md flex justify-center items-center mx-auto bg-gradient-to-l from-gradientSecondary to-gradientPrimary mt-6 md:px-6 text-white">Update Password</button>
                                                            )}
                                                  </form>
                                        </div>
                              </div>
                    </div>
          );
};
