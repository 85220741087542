import { HiArrowLongLeft } from "react-icons/hi2";
import { useNavigate, useParams } from "react-router-dom";
import GlobalLoader from "../../../../components/GlobalLoader";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { useGetSongByIdQuery } from "../../../../redux/features/SongApi";
import NewRelease from "../../UserDashboard/NewRelease/NewRelease";

export default function EditRelease() {
          useScrollToTop();

          const { id } = useParams<{ id: string }>();
          const navigate = useNavigate();

          const { data, isLoading } = useGetSongByIdQuery({ id });
          const song = data?.data?.song;

          if (isLoading) {
                    return <GlobalLoader />
          }

          return (
                    <div className="md:pb-5 pb-8 rounded-md">
                              <div className="card">
                                        <div className='my-4'>
                                                  <button className='flex items-center gap-2 cursor-pointer' onClick={() => navigate(-1)}><HiArrowLongLeft size={25} /><span className='text-base md:text-xl lg:text-2xl font-bold'>{song?.releaseTitle?.slice(0, 30)}</span></button>
                                        </div>
                                        <NewRelease song={song} />
                              </div>
                    </div>
          )
}