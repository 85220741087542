import React from 'react'
import useTitle from '../../../../hooks/useTitle'
import { Outlet, useNavigate } from 'react-router-dom';
import Title from '../../../../components/Title';

export default function ManageVerifyRequests() {
          useTitle('Manage Verify Requests');

          const navigate = useNavigate();

          let tabs = [
                    {
                              id: 1,
                              title: 'Pending Requests',
                              link: 'pending-requests'
                    },
                    {
                              id: 2,
                              title: 'Approved Requests',
                              link: 'approved-requests'
                    },
                    {
                              id: 3,
                              title: 'Rejected Requests',
                              link: 'rejected-requests'
                    }
          ];

          return (
                    <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
                              <Title title='Manage Verify Requests' subtitle='You can manage all verify requests from here' />
                              <div className="tabs tabs-boxed mt-5 gap-2 bg-transparent duration-300">
                                        {tabs.map((tab, i) => (
                                                  <span key={i} className={`tab md:tab-lg border 
                                                  ${tab.link === window.location.pathname.split('/')[2] ? 'tab-active' : ''}
                                                  ${window.location.pathname === '/all-verify-requests' && tab.title === 'Pending Requests' ? 'tab-active' : ''}
                                                  `}
                                                            onClick={() => navigate(`/all-verify-requests/${tab.link}`)
                                                            }>
                                                            {tab.title}
                                                  </span>
                                        ))}
                              </div>
                              <div>
                                        <Outlet />
                              </div>
                    </div>
          )
}
